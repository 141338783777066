<template>
	<div class="card" @click="toLink">
		<div
			class="card-1"
			:style="{
				'background-image': `url(${info.product_img})`,
			}"
		>
			<div class="tag" :class="`tag${info.status}`">
				<div>{{ info.status | toStr }}</div>
			</div>
		</div>

		<div class="card-2">
			<div class="card-2-1">编号 : {{ info.mac_id }}</div>
			<div class="card-2-2">
				库存：<span>{{ info.stock }}</span>
			</div>
			<div class="card-2-3">
				地区：{{
					info.province_name + info.city_name + info.county_name + info.address
				}}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			info: {
				type: Object,
				default: () => {},
			},
		},
		filters: {
			toStr: function (val) {
				switch (val) {
					case 0:
						return "离线";
					case 1:
						return "在线";
					case 2:
						return "异常";
				}
			},
		},
		methods: {
			// 跳转详情
			toLink() {
				this.$router.push(`/myEquipmentCardDetail?id=${this.info.mac_id}`)
			}
		}
	};
</script>

<style lang="scss" scoped>
	.card {
		width: 345px;
		height: 76px;
		padding: 10px 0;

		> div {
			float: left;
		}

		&-1 {
			width: 75px;
			height: 75px;
			border: 1px solid #f5f5f5;
			border-radius: 8px;
			background-size: cover;
			background-position: center 10%;

			.tag {
				width: 32px;
				height: 15px;
				line-height: 15px;
				border-radius: 8px 0;
				text-align: center;
				font-size: 12px;
				color: #ffffff;

				div {
					transform: scale(0.8333333333333334);
				}
			}

			.tag0 {
				background-color: rgba(255, 0, 0, 0.8);
			}

			.tag1 {
				background-color: rgba(0, 255, 0, 0.8);
			}

			.tag2 {
				background-color: rgba(255, 94, 0, 0.8);
			}
		}

		&-2 {
			width: 255px;
			padding-top: 4px;
			margin-left: 10px;

			> div {
				line-height: 100%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			&-1 {
				font-size: 16px;
				color: #000000;
			}

			&-2 {
				margin-top: 19px;
				font-size: 14px;
				color: #808080;

				span {
					color: #00a63a;
				}
			}

			&-3 {
				margin-top: 7px;
				font-size: 12px;
				color: #808080;
			}
		}
	}
</style>