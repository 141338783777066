<template>
	<div class="shop-card">
		<div class="img">
			<img :src="info.main_img || require('@/assets/temp/shop.png')" width="100%" />
		</div>
		<div class="content">
			<div class="title">{{ info.name }}</div>
			<div class="describe">{{ info.remark }}</div>
			<div class="information">
				<span class="price">￥{{ info.price }}</span>
				<span class="sold">已售{{ info.sold }}件</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "shopCard",
		props: {
			info: {
				type: Object,
				default: () => {},
			},
		},
	};
</script>

<style lang="scss" scoped>
	.shop-card {
		width: 345px;
		height: 180px;
		background: #ffffff;
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
		border-radius: 12px;
		margin: 0 auto 15px;
		padding-left: 3px;

		> div {
			float: left;
		}

		.img {
			width: 120px;
      height: 150px;
			padding-top: 15px;

			img {
				width: 100%;
        height: 100%;
			}
		}

		.content {
			width: 208px;
			margin-left: 3px;
			padding-top: 27px;

			.title {
				font-size: 20px;
				line-height: 20px;
				color: #000000;
			}

			.describe {
				width: 208px;
				height: 70px;
				font-size: 16px;
				line-height: 18px;
				color: #999999;
				margin-top: 8px;

				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 4;
				overflow: hidden;
			}

			.information {
				margin-top: 20px;
				height: 20px;
				display: flex;
				align-items: center;

				.price {
					font-size: 20px;
					line-height: 20px;
					color: #00a63a;
				}

				.sold {
					margin-left: 10px;
					font-size: 12px;
					color: #999999;
				}
			}
		}
	}
</style>