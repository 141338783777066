<template>
	<div class="revise">
		<van-form @submit="handleSubmit">
			<van-field
				v-model="data"
				name="name"
				label="昵称"
				placeholder="请输入昵称"
				input-align="right"
				error-message-align="right"
				:rules="[{ required: true, message: '' }]"
			/>

			<van-button class="btn" round block type="info" native-type="submit"
				>提交</van-button
			>
		</van-form>
	</div>
</template>

<script>
	import { Form, Field, Button } from "vant";
	import { $post } from "@/utils/request.js";
	export default {
		components: {
			vanForm: Form,
			vanField: Field,
			vanButton: Button,
		},
		data() {
			const { params } = this.$route;
			return {
				data: params.data,
			};
		},
		methods: {
			handleSubmit() {
				const { params } = this.$route;
				$post(params.url, {
					[params.key]: this.data,
				}).then(() => {
					params.method(this.data);
					this.$toast("昵称修改成功");
					this.$router.back();
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.revise {
		min-height: 100%;
		padding-top: 10px;
		box-sizing: border-box;
		background: #f2f5fc;

		.btn {
			width: 343px;
			height: 44px;
			font-size: 16px;
			color: #ffffff;
			margin: 40px auto 0;
			background: #00a63a;
		}
	}
</style>