<template>
	<div class="success">
		<div>
			<img
				class="success-1"
				:src="require('@/assets/authentication/success.png')"
			/>
			<div class="success-2">身份认证成功</div>
			<div class="success-3">身份认证成功，可以放心购买屏幕啦</div>
			<div class="success-4">返 回</div>
		</div>
	</div>
</template>

<script>
	export default {};
</script>

<style lang="scss" scoped>
	.success {
		min-height: 100%;
		background: #f2f5fc;
    display: flex;
    justify-content: center;
    align-items: center;

		> div {
			height: 430px;
			text-align: center;
      font-size: 0;
		}

		&-1 {
			width: 130px;
		}

		&-2 {
			margin-top: 30px;
			color: #000000;
			line-height: 100%;
			font-size: 20px;
		}

		&-3 {
			margin-top: 10px;
			color: #808080;
			line-height: 100%;
			font-size: 16px;
		}

		&-4 {
			width: 300px;
			height: 44px;
			line-height: 44px;
			background: #00a63a;
			font-size: 16px;
			margin: 129px auto 0;
			border-radius: 86px;
			color: #ffffff;
			text-align: center;
		}
	}
</style>