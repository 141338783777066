<template>
	<div class="detail-cell">
		<div
			:style="
				border && {
					'border-bottom': `1px solid ${border}`,
				}
			"
		>
			<div>{{ info.title }}</div>
			<div>{{ info.content }}</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			border: {
				type: String,
			},
			info: {
				type: Object,
				default: () => {},
			},
		},
	};
</script>

<style lang="scss" scoped>
	.detail-cell {
		padding: 16px 19px 0 16px;

		> div {
			padding-bottom: 15px;
			display: flex;

			div {
				font-size: 14px;
			}

			div:nth-of-type(1) {
				width: 46px;
				color: #999999;
			}

			div:nth-of-type(2) {
				width: 294px;
				color: #333333;
				line-height: 20px;
			}
		}
	}
</style>