<template>
	<van-uploader :after-read="afterRead">
		<slot></slot>
	</van-uploader>
</template>

<script>
	import { Uploader } from "vant";
	import { $post } from "@/utils/request.js";
	export default {
		components: {
			vanUploader: Uploader,
		},
		props: {
			url: {
				type: String,
				required: true,
			},
			parameter: {
				type: String,
				required: true,
			},
		},
		methods: {
			afterRead(file) {
				const formData = new FormData();
				formData.append("file", file.file);
				$post("/uploadToHome", formData)
					.then((res) => {
						return new Promise((resolve) => {
							if (this.url) {
								$post(this.url, {
									[this.parameter]: res[0].url,
								}).then(() => {
									resolve(res[0].complete_url);
								});
							} else {
								resolve(res[0]);
							}
						});
					})
					.then((res) => {
						this.$emit("finish", res);
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
</style>