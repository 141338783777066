<template>
	<div class="my-store">
		<van-tabs
			@change="handleChange"
			v-model="active"
			line-width="5rem"
			line-height="0.08rem"
			title-inactive-color="#272F37"
			title-active-color="#00A63A"
		>
			<van-tab
				:title="`已铺装店铺（${shop_data ? shop_data.install : 0}）`"
				replace
				name="already"
				:to="{ params: { page: 'already' } }"
			></van-tab>
			<van-tab
				:title="`待铺装店铺（${shop_data ? shop_data.uninstall : 0}）`"
				replace
				name="not"
				:to="{ params: { page: 'not' } }"
			></van-tab>
		</van-tabs>

		<Page
			class="my-store-container"
			@scroll.native="handleScroll"
			:state="state"
			:list="list.data"
		/>
	</div>
</template>

<script>
	import { Tab, Tabs } from "vant";
	import Page from "./Page.vue";
	import { $get } from "@/utils/request.js";
	export default {
		name: "my-store",
		components: {
			vanTab: Tab,
			vanTabs: Tabs,
			Page,
		},
		data() {
			return {
				active: this.$route.params.page,
				shop_data: this.$route.params.shop_data,
				list: { current_page: 1, data: [] },
				state: 0,
				scrollBol: false,
			};
		},
		async created() {
			this.upDateList();
			if (!this.shop_data) {
				await $get("/home/getSaleData").then((res) => {
					this.shop_data = res.shop_data;
				});
			}
		},
		methods: {
			handleChange() {
				this.list = { current_page: 1, data: [] };
				this.state = 0;
				this.upDateList();
			},
			upDateList() {
				const opts = { page: this.list.current_page };
				const install_status = Number(this.active === "already");
				!isNaN(install_status) && (opts.install_status = install_status);
				$get("/home/shop/list", opts).then((res) => {
					this.list = {
						...this.list,
						last_page: res.last_page,
						current_page: res.current_page,
						total: res.total,
					};
					this.list.data.push(...res.data);
					this.state++;
					this.scrollBol = false;
				});
			},
			handleScroll(e) {
				if (this.scrollBol) return;
				const { scrollTop, clientHeight, scrollHeight } = e.srcElement;
				if (scrollHeight - scrollTop - clientHeight < 5) {
					this.scrollBol = true;
					// 滚动条触底;
					if (this.list.lastPage > this.list.current_page) {
						this.list.current_page += 1;
						this.state++;
						this.upDateList();
					}
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.my-store {
		width: 100%;
		height: 100%;
		background: #f2f5fc;
		font-size: 14px;

		&-container {
			height: calc(100% - 70px);
			margin: 15px 0;
			padding: 0 15px;
			overflow: scroll;
			-webkit-overflow-scrolling: touch;
		}
	}
</style>