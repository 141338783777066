<template>
	<div
		class="card1"
		:style="{
			'background-image': `url(${require('@/assets/team/bg.png')})`,
		}"
	>
		<div class="card1-1">
			<img :src="info.avatar" class="card1-1-1" />
			<div class="card1-1-2">
				<div class="name">{{ info.realname }}</div>
				<div class="tag">
					<div v-for="(item, index) in info.roles" :key="index">{{ item }}</div>
				</div>
			</div>
			<router-link
				:to="{ name: 'myTeam', params: { info } }"
				custom
				v-slot="{ navigate }"
			>
				<div class="card1-1-3" @click="navigate">
					<img :src="require('@/assets/team/myTeam.png')" />
					<span>我的团队</span>
				</div>
			</router-link>
		</div>

		<Info
			class="card1-2"
			v-for="(item, index) in info.condition"
			:key="index"
			:proportion="+item.rates || 0"
		>
			<template #left>{{ item.start_str }}</template>
			<template #right>{{ item.end_str }}</template>
		</Info>
	</div>
</template>

<script>
	import Info from "./Info.vue";
	export default {
		components: {
			Info,
		},
		props: {
			info: {
				type: Object,
				default: () => {},
			},
		},
	};
</script>

<style lang="scss" scoped>
	.card1 {
		width: 375px;
		padding-bottom: 30px;
		background-size: 108% auto;
		background-position: center;

		&-1 {
			display: flex;
			align-items: center;
			padding: 20px 0 0 20px;

			&-1 {
				width: 70px;
				height: 70px;
				border-radius: 50%;
			}

			&-2 {
				width: 157px;
				height: 70px;
				margin-left: 10px;
				padding-top: 15px;
				box-sizing: border-box;

				.name {
					font-size: 16px;
					color: #ffffff;
					line-height: 100%;
				}

				.tag {
					margin-top: 8px;
					height: 26px;
					overflow: scroll;
					white-space: nowrap;
					font-size: 0;

					div {
						display: inline-block;
						padding: 0 12px;
						line-height: 20px;
						font-size: 12px;
						background: #fff1d9;
						border-radius: 100px;
						color: #a68650;
						margin-left: 10px;
					}

					div:nth-of-type(1) {
						margin: 0;
					}
				}
			}

			&-3 {
				width: 101px;
				height: 32px;
				margin-left: 10px;
				background: #53575c;
				box-shadow: 2px 2px 4px rgba(64, 39, 0, 0.4);
				border-radius: 100px 0px 0px 100px;
				padding-left: 7px;
				display: flex;
				align-items: center;

				img {
					width: 25px;
				}

				span {
					font-size: 16px;
					color: #ffffff;
					line-height: 100%;
					margin-left: 2px;
				}
			}
		}

		&-2 {
			margin: 30px auto 0;
		}
	}
</style>