<template>
	<div class="autograph">
		<div class="autograph-1">
			<div :style="{ width: `${width}px`, height: `${height}px` }">
				<vue-esign
					class="canvas"
					ref="esign"
					:width="width"
					:height="height"
					:isCrop="isCrop"
					:lineWidth="lineWidth"
					:lineColor="lineColor"
					:bgColor.sync="bgColor"
				/>
			</div>
		</div>

		<div class="autograph-2">
			<div @click="handleReset">重写签名</div>
			<div @click="handleGenerate">确定</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "autograph",
		data() {
			const windowWidth = document.documentElement.clientWidth;
			const windowHeight = document.documentElement.clientHeight;
			const availableWidth = (345 / 375) * windowWidth;
			const availableHeight = (638 / 750) * windowHeight;
			const canvasBili = 345 / 638;

			let width;
			let height;

			if (availableWidth / canvasBili > availableHeight) {
				height = availableHeight;
				width = height * canvasBili;
			} else {
				width = availableWidth;
				height = width / canvasBili;
			}

			return {
				width,
				height,
				lineWidth: 6,
				lineColor: "#000000",
				bgColor: "",
				resultImg: "",
				isCrop: false, // 是否裁剪
			};
		},
		methods: {
			handleReset() {
				this.$refs.esign.reset();
			},
			handleGenerate() {
				this.$refs.esign
					.generate()
					.then((res) => {
						res;
						// console.log(res); // base64图片
						console.log(this.$refs.esign);
					})
					.catch((err) => {
						alert(err); // 画布没有签字时会执行这里 'Not Signned'
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.autograph {
		&-1,
		&-2 {
			display: flex;
		}

		&-1 {
			height: 90vh;
			justify-content: center;
			align-items: center;

			div {
				background: #f2f6fc;
			}
		}

		&-2 {
			height: 10vh;
			padding: 0 16px;
			justify-content: space-between;
      align-items: flex-start;

			div {
				width: 160px;
				height: 44px;
				line-height: 44px;
				text-align: center;
				border-radius: 109px;
				font-size: 16px;
			}

			div:nth-of-type(1) {
				border: 1px solid #00a63a;
				color: #00a63a;
			}

			div:nth-of-type(2) {
				background: #00a63a;
				color: #ffffff;
			}
		}
	}
</style>