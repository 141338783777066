<template>
	<div
		class="eliteRanking"
		:style="{
			'background-image': `url(${require('@/assets/eliteRanking/bg.png')})`,
		}"
	>
		<img :src="require('@/assets/eliteRanking/word.png')" />
		<MyCard class="eliteRanking-1" :info="info.my_ranking" />
		<div class="eliteRanking-2">
			<ItemCard v-for="item in info.user_ranking" :key="item.id" :info="item" />
		</div>
	</div>
</template>

<script>
	import MyCard from "./MyCard.vue";
	import ItemCard from "./ItemCard";
	import { $get } from "@/utils/request.js";
	export default {
		name: "eliteRanking",
		components: {
			MyCard,
			ItemCard,
		},
		data() {
			return {
				info: {},
			};
		},
		created() {
			$get("/home/getUserRankingData").then((res) => {
				this.info = res;
			});
		},
	};
</script>

<style lang="scss" scoped>
	.eliteRanking {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		padding-top: 2px;
		background-size: cover;
		background-position: center top;
		font-size: 0;
		text-align: center;

		img {
			width: 106px;
		}

		&-1 {
			background: #ffffff;
			border-radius: 10px;
			margin: 21px auto 0;
		}

		&-2 {
			width: 355px;
			height: calc(100% - 221px);
			background: #ffffff;
			border-radius: 10px;
			margin: 10px auto 0;
			overflow: scroll;
			-webkit-overflow-scrolling: touch;

			.item-card {
				border-bottom: 1px solid #f2f5fc;
			}

			.item-card:nth-last-of-type(1) {
				border-bottom: 0;
			}
		}
	}
</style>