<template>
	<div class="equipment-revenue">
		<div class="equipment-revenue-1">
			<div>累计收益 : 999.99 元</div>
			<div>今日收益 : 99.99 元</div>
		</div>

		<div class="equipment-revenue-2">
			<div>时间</div>
			<div>扫码人</div>
			<div>收益</div>
		</div>

		<div class="equipment-revenue-3" v-for="i in 10" :key="i">
			<div>2021-09-10 13:54</div>
			<div>微信昵称</div>
			<div>0.35</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "equipmentRevenue",
	};
</script>

<style lang="scss" scoped>
	.equipment-revenue {
		min-height: 100%;
		background: #f2f5fc;

		&-1 {
			padding: 10px 15px;
			line-height: 100%;
			font-size: 13px;
			color: #808080;
			display: flex;
			justify-content: space-between;
		}

		&-2,
		&-3 {
			line-height: 100%;
			padding: 9px;
			display: flex;

			div {
				text-align: center;
			}

			div:nth-of-type(1) {
				width: 140px;
			}

			div:nth-of-type(2) {
				width: 126px;
			}

			div:nth-of-type(3) {
				width: 109px;
				border: none;
			}
		}

		&-2 {
			padding: 9px;
			background: #00a63a;
			font-size: 16px;
			color: #ffffff;

			div {
				border-right: 1px solid #00a63a;
			}
		}

		&-3 {
			font-size: 14px;
			color: #808080;
		}

		&-3:nth-of-type(2n + 1) {
			background: #f2f6fc;

			div {
				border-right: 1px solid #ffffff;
			}
		}

		&-3:nth-of-type(2n) {
			background: #ffffff;

			div {
				border-right: 1px solid #f2f6fc;
			}
		}
	}
</style>