<template>
  <div class="cashOut">
    <p class="name">提现到</p>
    <div class="card" @click="changeBankPopupBol">
      <div class="left">
        <p class="name">
          {{ bank_data.bank_name }}（ xxxx
          {{ bank_data.short_bank_account || bank_data.bank_account }} ）
        </p>
        <p class="desc">{{ withdrawInfo.withdraw_tips }}</p>
      </div>
      <div class="right">
        <img src="@/assets/cashOut/right_icon.png" alt="" width="100%" />
      </div>
    </div>

    <div class="inpBox">
      <p class="name">提现金额</p>
      <div class="middle">
        <span class="mark">￥</span>
        <input type="number" v-model="money" />
        <span class="allCashOutBtn" @click="money = withdrawInfo.money"
          >全部提现</span
        >
      </div>
      <div class="descBox">
        <p class="desc">可提现余额 ￥{{ withdrawInfo.money }}</p>
        <p class="desc">冻结余额 ￥{{ withdrawInfo.frozen_money }}</p>
      </div>
    </div>
    <p class="ruleName" @click="ruleDialogBol = true">查看提现规则  ></p>
    <div class="cashOutBtn">
      <button
        :disabled="btnDisabled"
        class="btn"
        :class="{ opacity: btnDisabled }"
        @click="submitWithdraw"
      >
        提现
      </button>
    </div>

    <!-- 选择银行卡 -->
    <van-popup class="bankBox" v-model="bankPopupBol" position="bottom">
      <div class="header">
        <img
          class="icon"
          src="@/assets/cashOut/right_icon.png"
          alt=""
          @click="bankPopupBol = false"
        />
        选择提现银行卡
      </div>
      <div class="content">
        <div
          class="card"
          v-for="(item, index) in bankList"
          :key="index"
          @click="handleBank(item)"
        >
          <div class="left">
            <p class="name">
              {{ item.bank_name }}（ xxxx {{ item.short_bank_account }} ）
            </p>
            <p class="desc">{{ withdrawInfo.withdraw_tips }}</p>
          </div>
          <div class="right">
            <img
              :src="
                require(`@/assets/cashOut/sel${
                  bank_data.id == item.id ? '_active' : ''
                }.png`)
              "
              alt=""
              width="100%"
            />
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 提现规则弹框 -->
    <van-popup class="ruleDialogBox" position="bottom" v-model="ruleDialogBol">
      <div class="ruleDialogMain" v-html="withdrawInfo.withdraw_desc"></div>
    </van-popup>
  </div>
</template>

<script>
import { $get, $post } from "@/utils/request.js";
import { Popup } from "vant";
export default {
  data() {
    return {
      withdrawInfo: {}, // 获取提现信息
      money: "", // 提现金额
      bankList: [], // 银行卡列表
      bankPopupBol: false, // 银行卡弹出框 显示/隐藏

      bank_data: {}, // 当前提现银行卡信息

      isClick: true, // 是否可点击

      ruleDialogBol: false // 提现规则弹框
    };
  },
  computed: {
    //   提现按钮是否可点击
    btnDisabled() {
      console.log(
        Number(this.money) <= Number(this.withdrawInfo.money),
        "00000000000"
      );
      if (
        Number(this.money) > 0 &&
        Number(this.money) <= Number(this.withdrawInfo.money) &&
        this.bank_data.id
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  components: {
    vanPopup: Popup,
  },
  created() {
    this.getWithdrawInfo();
  },
  methods: {
    // 获取提现信息
    getWithdrawInfo() {
      $get("/home/getWithdrawInfo").then((res) => {
        if (!res.user_type) {
          //   个人用户 - 可切换银行卡
          this.getBankList();
        }
        this.bank_data = res.bank_data;
        this.withdrawInfo = res;
      });
    },

    // 查询银行卡列表
    getBankList() {
      $get("/home/userBank/list").then((res) => {
        this.bankList = res;
      });
    },

    // 选择银行卡 弹框显示
    changeBankPopupBol() {
      if (this.bankList.length) {
        this.bankPopupBol = true;
      }
    },

    // 选中银行卡
    handleBank(item) {
      this.bank_data = item;
      this.bankPopupBol = false;
    },

    // 提交提现申请
    submitWithdraw() {
      if (this.isClick) {
        this.isClick = false;
        $post("/home/submitWithdraw", {
          money: this.money,
          bank_id: this.bank_data.id,
        })
          .then((res) => {
            this.$toast("提交申请成功");
            this.money = "";
            this.isClick = true;
            this.getWithdrawInfo();
          })
          .catch((err) => {
            this.isClick = true;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cashOut {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 22px 15px;
  background-color: #f2f5fc;
}

.cashOut .name {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
}

.cashOut .desc {
  color: #999999;
  font-size: 13px;
  line-height: 23px;
}

.cashOut .card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}
.cashOut .card .name {
  margin-bottom: 8rpx;
}
.cashOut .card .right {
  width: 24px;
  height: 24px;
}

.cashOut .inpBox {
  width: 100%;
  padding: 15px 12px 2px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 12px;
}
.cashOut .inpBox .middle {
  padding: 10px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f5fc;
}
.cashOut .inpBox .middle input {
  width: 220px;
  margin: 0 5px;
  color: #000000;
  font-size: 30px;
  font-weight: bold;
  line-height: 51px;
  outline: none;
  border: none;
}
.cashOut .inpBox .middle .mark {
  color: #000000;
  font-size: 30px;
  font-weight: bold;
  line-height: 51px;
}
.cashOut .inpBox .middle .allCashOutBtn {
  color: #00a63a;
  font-size: 13px;
  font-weight: 400;
  line-height: 23px;
}

.cashOut .inpBox .descBox {
  display: flex;
  padding: 14px 0;
}
.cashOut .inpBox .descBox .desc {
  margin-right: 30px;
}

.ruleName {
  color: #999;
  font-size: 13px;
  line-height: 23px;
  text-align: center;
  margin-top: 30px;
}

.cashOutBtn {
  margin-top: 80px;
  text-align: center;
}
.cashOutBtn .btn {
  width: 300px;
  height: 44px;
  box-sizing: border-box;
  line-height: 44px;
  background-color: #00a63a;
  border-radius: 109px;
  box-shadow: 2px 2px 4px rgba(0, 50, 17, 0.2);
  border: none;
  padding: 0;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.cashOutBtn .btn.opacity {
  opacity: 0.4;
}

// 选择银行卡
.bankBox {
  width: 100%;
  box-sizing: border-box;
  padding: 16px 14px 32px;
  border-radius: 20px 20px 0 0;
  color: #000000;
  font-weight: 400;
}
.bankBox .header {
  text-align: center;
  position: relative;
  font-size: 16px;
  line-height: 27px;
}
.bankBox .header .icon {
  width: 36px;
  position: absolute;
  top: -4px;
  left: -5px;
  transform: rotate(180deg);
}
.bankBox .content .card {
  padding: 15px 0;
  border-bottom: 1px solid #f2f5fc;
  margin: 0;
}
.bankBox .right {
  width: 16px;
  height: 16px;
}

// 提现规则弹框
.ruleDialogBox {
  width: 100%;
  max-height: 80%;
  box-sizing: border-box;
  padding: 20px 15px;
  border-radius: 4px 4px 0 0;
}
.ruleDialogBox .ruleDialogMain {
  width: 100%;
}
</style>