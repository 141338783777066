<template>
	<div class="badge" :style="{ width }">
		<img :src="info.url" v-if="info.url"/>
		<span :style="{ color }">{{ info.content }}</span>
		<div v-if="info.count">
			{{ info.count > info.max ? info.max + "+" : info.count }}
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			info: {
				type: Object,
				default: () => {},
			},
			width: {
				type: String,
				default: "1.22rem",
			},
			color: {
				type: String,
				default: "#5B5B5B",
			},
		},
	};
</script>

<style lang="scss" scoped>
	.badge {
		height: 47px;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			width: 32px;
			height: 32px;
		}

		span {
			font-size: 14px;
			line-height: 100%;
		}

		div {
			position: absolute;
			height: 16px;
			line-height: 17px;
			border-radius: 8px;
			font-size: 12px;
			padding: 0 4.6625px;
			color: #ffffff;
			background: #ff0000;
			left: 21px;
			top: -2px;
		}
	}
</style>