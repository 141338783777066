<template>
	<div
		class="share page"
		:style="{
			'background-image': `url(${require('@/assets/share/bg.png')})`,
		}"
	>
		<router-view class="page" />
	</div>
</template>

<script>
	export default {
		name: "share",
	};
</script>

<style lang="scss" scoped>
	.page {
		width: 100%;
		height: 100%;
	}

	.share {
		background: #00a63a;
		background-size: 344px;
		background-repeat: no-repeat;
		background-position: center 33px;
	}
</style>