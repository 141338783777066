<template>
	<div class="signature">
		<!-- <template v-if="autograph">
			<div class="signature-1-1">
				<span>购买者签名</span>
				<img src="" />
			</div>
			<div class="signature-1-2" @click="signedConfirm">确 定</div>
		</template> -->
		<!-- <div class="signature-2" v-else @click="handleClick">点击全屏手写签名</div> -->
		<div class="signature-2" @click="handleClick">购买者签名</div>
	</div>
</template>

<script>
	export default {
		name: "signature",
		props: {
			autograph: {
				type: String,
				default: "",
			},
		},
		methods: {
			handleClick() {
				this.$emit("confirm");
			},
			signedConfirm() {
				this.$emit("signedConfirm");
			},
		},
	};
</script>

<style lang="scss" scoped>
	.signature {
		background: #ffffff;
		padding: 15px 16px;
		font-size: 0;
		box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1);

		&-1 {
			&-1 {
				span {
					font-size: 16px;
					line-height: 100%;
				}

				img {
					margin-top: 10px;
					width: 344px;
					height: 60px;
					background: #f2f6fc;
					border-radius: 2px;
				}
			}

			&-2 {
				margin-top: 15px;
				width: 343px;
				height: 44px;
				line-height: 44px;
				text-align: center;
				background: #00a63a;
				border-radius: 86px;
				font-size: 16px;
				color: #ffffff;
			}
		}

		&-2 {
			width: 343px;
			line-height: 44px;
			text-align: center;
			background: #00a63a;
			font-size: 16px;
			color: #ffffff;
			border-radius: 86px;
		}
	}
</style>