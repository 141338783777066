<template>
  <div class="shop" :class="{ noIdentity: userInfo.icheck }">
    <div class="scroll">
      <router-link
        v-for="(item, index) in list"
        :key="index"
        :to="{ name: 'detail', params: { id: item.id } }"
        custom
        v-slot="{ navigate }"
      >
        <Card :info="item" @click.native="navigate" />
      </router-link>
    </div>
    <Identity v-if="!userInfo.icheck" />
  </div>
</template>

<script>
import Card from "./Card.vue";
import Identity from "./Identity.vue";
import { $get } from "@/utils/request.js";
import { mapState } from "vuex";
export default {
  name: "shop",
  components: {
    Card,
    Identity,
  },
  computed: {
    ...mapState(["userInfo"])
  },
  data() {
    return {
      list: [], // 商品列表
    };
  },
  created() {
    this.getList();
  },
  methods: {

    // 获取商品列表
    getList() {
      $get("/home/product/list").then((res) => {
        this.list = res;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.shop {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #f2f5fc;
  padding-bottom: env(safe-area-inset-bottom);

  &.noIdentity .scroll {
    height: 100%;
  }

  .scroll {
    box-sizing: border-box;
    padding-top: 15px;
    overflow: scroll;
    height: calc(100% - 104px);
    -webkit-overflow-scrolling: touch;
  }
}
</style>