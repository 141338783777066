<template>
	<div
		:style="
			state && !list.length && {
				'background-image': `url(${require('@/assets/other/noData.png')})`,
			}
		"
	>
		<Card v-for="item in list" :key="item.id" :info="item" />

		<van-loading
			vertical
			size="0.8rem"
			text-size="0.4rem"
			v-if="state % 2 !== 1"
			>加载中...</van-loading
		>
	</div>
</template>

<script>
	import { Loading } from "vant";
	import Card from "./Card.vue";
	export default {
		props: {
			state: {
				type: Number,
				default: 0,
			},
			list: {
				type: Array,
				default: () => [],
			},
		},
		components: {
			vanLoading: Loading,
			Card,
		},
	};
</script>

<style lang="scss" scoped>
	div {
		background-size: 222px auto;
		background-repeat: no-repeat;
		background-position: center 40%;
	}

	.card {
		margin-bottom: 10px;
	}

	.card:nth-last-of-type(1) {
		margin: 0;
	}

	.van-loading {
		position: absolute;
		top: 45%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
</style>