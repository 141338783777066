var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"join-team"},[_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('div',{staticClass:"join-team-1"},_vm._l((_vm.info[0]),function(item,index){return _c('van-field',{key:index,attrs:{"name":item.name,"label":item.label,"placeholder":item.placeholder,"input-align":"right","error-message-align":"right","rules":item.rules},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})}),1),_c('div',{staticClass:"mt10"},[_vm._l((_vm.info[1]),function(item,index){return [_c('van-field',{key:item.key,staticClass:"red",class:item.class,attrs:{"readonly":"","required":"","name":item.name,"value":item.value.text,"label":item.label,"placeholder":"请选择","input-align":"right","error-message-align":"right","right-icon":"arrow","rules":[
						{
							required: true,
							message: item.message,
							trigger: 'onChange',
						} ]},on:{"click":function($event){item.showPicker = true}}}),_c('van-popup',{key:index,attrs:{"position":"bottom"},model:{value:(item.showPicker),callback:function ($$v) {_vm.$set(item, "showPicker", $$v)},expression:"item.showPicker"}},[(item.class)?_c('van-area',{attrs:{"area-list":item.columns,"columns-num":_vm.info[1][1].value ? +_vm.info[1][1].value.code : 3,"loading":item.loading},on:{"cancel":function($event){item.showPicker = false},"confirm":function($event){return _vm.onConfirm($event, 1, index)}}}):_c('van-picker',{attrs:{"show-toolbar":"","columns":item.columns},on:{"confirm":function($event){return _vm.onConfirm($event, 1, index)},"cancel":function($event){item.showPicker = false}}})],1)]})],2),_c('div',{staticClass:"mt10"},[_vm._l((_vm.info[2]),function(item,index){return [_c('van-field',{key:item.name,staticClass:"textarea",attrs:{"type":"textarea","maxlength":"120","required":"","border":false,"name":item.name,"label":item.label,"placeholder":item.placeholder,"rules":[{ required: true, message: '' }]},on:{"input":function($event){return _vm.handleInput($event, 2, index)}},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}}),_c('div',{key:index,staticClass:"wordsTips border"},[_vm._v(" 还可以输入 "),_c('span',[_vm._v(_vm._s(120 - item.wordsTips))]),_vm._v(" 字 ")])]})],2),_c('div',{staticClass:"mt10"},[_c('van-field',{staticClass:"red title10",attrs:{"readonly":"","required":"","name":_vm.info[3][0].name,"value":_vm.info[3][0].value.text,"label":_vm.info[3][0].label,"placeholder":"请选择","input-align":"right","error-message-align":"right","right-icon":"arrow","rules":[
					{
						required: true,
						message: _vm.info[3][0].message,
						trigger: 'onChange',
					} ]},on:{"click":function($event){_vm.info[3][0].showPicker = true}}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.info[3][0].showPicker),callback:function ($$v) {_vm.$set(_vm.info[3][0], "showPicker", $$v)},expression:"info[3][0].showPicker"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.info[3][0].columns},on:{"confirm":function($event){return _vm.onConfirm($event, 3, 0)},"cancel":function($event){_vm.info[3][0].showPicker = false}}})],1),_c('van-field',{attrs:{"name":_vm.info[3][1].name,"label":_vm.info[3][1].label,"placeholder":_vm.info[3][1].placeholder,"required":"","input-align":"right","error-message-align":"right","rules":[{ required: true, message: '' }]},model:{value:(_vm.info[3][1].model),callback:function ($$v) {_vm.$set(_vm.info[3][1], "model", $$v)},expression:"info[3][1].model"}})],1),_c('van-button',{staticClass:"btn",attrs:{"round":"","block":"","type":"info","native-type":"submit"}},[_vm._v("提交")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }