<template>
	<div class="card">
		<img class="card-1" :src="info.product_img" />
		<div class="card-2">
			<div>{{ info.product_cate_name }}</div>
			<div>屏幕编号：{{ info.mac_id }}</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			info: {
				type: Object,
				default: () => {},
			},
		},
	};
</script>

<style lang="scss" scoped>
	.card {
		width: 345px;
		height: 106px;
		box-sizing: border-box;
		background: #ffffff;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.16);
		border-radius: 12px;
		padding: 15px;

		&-1,
		&-2 {
			float: left;
		}

		&-1 {
			width: 76px;
			height: 76px;
			box-sizing: border-box;
			border-radius: 8px;
			border: 1px solid #f5f5f5;
			background: radial-gradient(
				circle,
				rgba(255, 255, 255, 0) 0%,
				rgba(182, 182, 182, 0.1) 100%
			);
		}

		&-2 {
			width: 229px;
			margin-left: 10px;
			padding-top: 16px;

			div {
				line-height: 100%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			div:nth-of-type(1) {
				font-size: 18px;
			}

			div:nth-of-type(2) {
				margin-top: 15px;
				font-size: 12px;
				color: #808080;
			}
		}
	}
</style>