<template>
	<div
		class="totalCard"
		:style="{
			'background-image': `url(${require('@/assets/myEquipment/bg.png')})`,
		}"
	>
		<div class="totalCard-1">
			<span>{{ Number(count).toLocaleString() }}</span>
			<van-icon name="replay" @click="$emit('reset')" />
		</div>

		<div class="totalCard-2">出袋总数</div>
	</div>
</template>

<script>
	import { Icon } from "vant";
	export default {
		components: {
			vanIcon: Icon,
		},
		props: {
			count: {
				type: Number,
				default: 0,
			},
		},
	};
</script>

<style lang="scss" scoped>
	.totalCard {
		width: 375px;
		height: 160px;
		padding-top: 48px;
		box-sizing: border-box;
		background-size: 100% 100%;
		color: #ffffff;

		&-1 {
			line-height: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			span {
				font-size: 30px;
				margin-left: 23px;
			}

			.van-icon {
				transform: translateY(-1px) rotateZ(45deg);
				font-size: 24px;
				font-weight: bold;
				margin-left: 9px;
			}
		}

		&-2 {
			margin-top: 25px;
			font-size: 16px;
			line-height: 100%;
			text-align: center;
		}
	}
</style>