<template>
	<div class="my-equipment">
		<TotalCard :count="statistics.start_count" @reset="handleReset" />

		<div class="my-equipment-2">
			<template v-for="(item, index) in list">
				<Pieces :key="index" :info="item" />
				<div class="border" :key="index + 4" v-if="index !== 3"></div>
			</template>
		</div>

		<div class="my-equipment-3">
			<Card v-for="item in info.data" :key="item.id" :info="item" />
		</div>

		<van-loading
			vertical
			size="0.8rem"
			text-size="0.4rem"
			v-if="state % 2 !== 1"
			>加载中...</van-loading
		>
	</div>
</template>

<script>
	import { $get } from "@/utils/request.js";
	import TotalCard from "./TotalCard.vue";
	import Pieces from "./Pieces.vue";
	import { Loading } from "vant";
	import Card from "./Card.vue";
	export default {
		name: "myEquipment",
		components: {
			vanLoading: Loading,
			TotalCard,
			Pieces,
			Card,
		},
		data() {
			return {
				statistics: {},
				list: [],
				info: {
					current_page: 1,
					data: [],
				},
				state: 0,

				scroll: false,
			};
		},
		created() {
			this.myMachineStatistics();
			this.myMachineList();
			const ele = document.documentElement;
			window.onscroll = () => {
				// this.$toast("触发===" +this.scroll);
				// console.log("ele.scrollHeight===" + ele.scrollHeight)
				// console.log("ele.scrollTop===" + ele.scrollTop)
				// console.log("ele.clientHeight===" + ele.clientHeight)
				// var oTop=document.body.scrollTop==0?document.documentElement.scrollTop:document.body.scrollTop;
				// console.log("oTop===" + oTop)
				var oTop1=ele.scrollTop==0?document.body.scrollTop:ele.scrollTop;
				// console.log("oTop===" + oTop1)
				// this.$toast("===" +ele.scrollHeight - ele.scrollTop - ele.clientHeight);
				
				if (this.scroll) return;
				if (ele.scrollHeight - oTop1 - ele.clientHeight <= 5) {
					this.scroll = true;
					if (this.info.last_page > this.info.current_page) {
						this.info.current_page += 1;
						this.myMachineList();
					}
				}
			};
		},
		beforeDestroy() {
			window.onscroll = null;
		},
		methods: {
			handleReset() {
				this.state++;
				this.myMachineStatistics();
			},
			myMachineStatistics() {
				$get("/home/machine/myMachineStatistics").then((res) => {
					this.statistics = res;

					this.list = [
            {
              count: res.mac_total,
              describe: "设备总数",
            },
            {
              count: res.mac_unonline,
              describe: "设备离线",
            },
						{
							count: res.mac_abnormal,
							describe: "设备异常",
						},
						{
							count: res.yestoday_scan_user_count,
							describe: "今日扫码人数",
						},
					];

					this.state++;
				});
			},
			myMachineList() {
				$get("/home/machine/myMachineList", {
					page: this.info.current_page,
				}).then((res) => {
					this.info = {
						...this.info,
						current_page: res.current_page,
						last_page: res.last_page,
					};

					this.info.data.push(...res.data);
					this.scroll = false;
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.my-equipment {
		background-color: #f2f5fc;
		min-height: 100%;
		overflow-y: hidden;

		&-2 {
			padding: 14px 0;
			margin-bottom: 10px;
			display: flex;
			align-items: center;
			background: #ffffff;
			box-shadow: 0px 1px 1px #f2f6fc;

			.border {
				width: 1px;
				height: 30px;
				background: #f2f6fc;
			}
		}

		&-3 {
			padding: 0 15px;
			background: #ffffff;

			.card {
				border-bottom: 1px solid #f2f6fc;
			}
		}

		.van-loading {
			position: absolute;
			top: 45%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
</style>