<template>
  <div class="myEquipmentCardDetailBox">
    <!-- 设备信息 -->
    <div class="infoListBox">
      <div class="list">
        <div class="name">设备编号</div>
        <div class="val">{{ info.mac_id }}</div>
      </div>
      <div class="list">
        <div class="name">设备地址</div>
        <div class="val">
          {{ info.province_name }}{{ info.city_name }}{{ info.county_name }}
        </div>
      </div>
      <div class="list">
        <div class="name">详细地址</div>
        <div class="val">{{ info.address }}</div>
      </div>
      <div class="dataListBox">
        <div class="dataList" v-for="(item, index) in dataList" :key="index">
          <span class="sum">{{ info[item.sumKey] }}</span>
          <p class="name">{{ item.name }}</p>
        </div>
      </div>
    </div>

    <!-- 近10日数据 -->
    <div class="tenDayOrderList">
      <p class="title">近10天数据</p>
      <div class="canvasBox">
        <canvas id="lineBox"></canvas>
      </div>
    </div>

    <!-- 收益明细 -->
    <div class="detailListBox">
      <div class="detailListTop">
        <p class="title">收益明细</p>
        <div class="descBox">
          <p class="left">累计出袋子 : {{ info.order_total }} 个</p>
          <p class="right">今日出袋 : {{ info.order_today }} 个</p>
        </div>
      </div>

      <div class="tableBox" :class="{ tableBoxFixed: tableHeaderFixed }">
        <div class="header" id="tableHeader">
          <div>时间</div>
          <div>扫码人数</div>
          <div>出袋数</div>
        </div>

        <div class="list" v-for="(item, index) in detailList" :key="index">
          <div>{{ item.created_at }}</div>
          <div>{{ item.scan_user_count }}</div>
          <div>{{ item.start_count }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { $get } from "@/utils/request.js";

export default {
  data() {
    return {
      dataList: [
        {
          sumKey: "order_total",
          name: "出袋总数",
        },
        {
          sumKey: "order_today",
          name: "今日出袋",
        },
        {
          sumKey: "stock",
          name: "剩余库存",
        },
      ], // 设备信息数据列表

      info: {}, // 设备详细数据

      page: 1, // 当前页
      last_page: 0, // 最后页
      detailList: [], // 设备出袋明细列表

      tableHeaderFixed: false, // 明细header吸顶

      // 指定图表的配置项和数据
      lineOption: {
        grid: {
          top: "10%",
          bottom: "3%",
          left: "3%",
          right: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [],
          axisLine: {
            lineStyle: {
              color: "#DEE4E8",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#999999",
              fontSize: 10,
              fontWeight: 400,
            },
            align: "right",
            verticalAlign: "top",
            rotate: 40,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              fontSize: 8,
            },
          },
        },
        series: [
          {
            data: [],
            type: "line", // 类型为折线图
          },
        ],
      },
    };
  },
  methods: {
    //   设备详细数据
    getMyMachineList() {
        console.log(this.$route.query.id)
      $get(`/home/machine/myMachineDetail/${this.$route.query.id}`).then(
        (res) => {
          this.info = res;
          let xAxisData = [],
            seriesData = [];
          res.ten_day_order_list.forEach((item) => {
            xAxisData.push(item.created_at);
            seriesData.push(item.count);
          });

          //   折线图
          this.lineOption.xAxis.data = xAxisData;
          this.lineOption.series[0].data = seriesData;
          let chartLine = this.$echarts.init(
            document.getElementById("lineBox")
          );
          chartLine.setOption(this.lineOption);

          this.getMyMachinestatisticsRecordsDetail();
        }
      );
    },

    //   设备出袋记录明细
    getMyMachinestatisticsRecordsDetail() {
      $get(`/home/machine/myMachinestatisticsRecordsDetail`, {
        mac_id: this.$route.query.id,
        page: this.page,
      }).then((res) => {
        this.last_page = res.last_page;
        this.detailList.push(...res.data);
      });
    },

    // 滚动条事件
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (!this.offsetTop) {
        this.offsetTop = document.querySelector("#tableHeader").offsetTop;
      }
      let offsetHeight = document.documentElement.offsetHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop > this.offsetTop) {
        //   吸顶
        this.tableHeaderFixed = true;
      } else {
        this.tableHeaderFixed = false;
      }

      //   触底判断
      if (offsetHeight + scrollTop - scrollHeight >= -1) {
        if (this.page < this.last_page) {
          this.page += 1;
          this.getMyMachinestatisticsRecordsDetail();
        }
      }
    },
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.getMyMachineList();
  },

  //   销毁
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.myEquipmentCardDetailBox {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #fff;
}
.title {
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  line-height: 34px;
  margin-bottom: 20px;
}

// 设备信息 ---start---
.infoListBox {
  width: 100%;
  box-sizing: border-box;
  border-bottom: 10px solid #f2f5fc;
}
.infoListBox .name {
  color: #323233;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
}
.infoListBox .val {
  max-width: 60%;
  color: #808080;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.infoListBox .list {
  width: 100%;
  box-sizing: border-box;
  padding: 17px 15px 13px 16px;
  border-bottom: 1px solid #f2f6fc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.infoListBox .dataListBox {
  display: flex;
  .dataList {
    flex: 1 1 auto;
    text-align: center;
    padding: 14px 0 13px;
    .sum {
      color: #00a63a;
      font-size: 18px;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 10px;
    }
  }
  .dataList:nth-child(2) {
    position: relative;
  }
  .dataList:nth-child(2)::before {
    content: "";
    width: 1px;
    height: 30px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #f2f6fc;
  }
  .dataList:nth-child(2)::after {
    content: "";
    width: 1px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #f2f6fc;
  }
  .dataList:last-child .sum {
    color: #f92d2d;
  }
}
// 设备信息 ---start---

// 近10日数据 ---start---
.tenDayOrderList {
  display: none;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 15px 20px;
  border-bottom: 10px solid #f2f5fc;
  .canvasBox {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #f2f6fc;
    border-radius: 4px;
    #lineBox {
      width: 100%;
      font-size: 12px;
    }
  }
}
// 近10日数据 ---end---

// 收益明细 ---start---
.detailListBox {
  width: 100%;
  box-sizing: border-box;
  .detailListTop {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 15px 0;
    .descBox {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #808080;
      font-size: 13px;
      font-weight: 300;
      line-height: 23px;
      padding: 10px 0;
    }
  }
  .tableBox {
    &.tableBoxFixed {
      padding-top: 34px;
      .header {
        width: 100%;
        box-sizing: border-box;
        position: fixed;
        top: 0;
      }
    }
    .header,
    .list {
      line-height: 100%;
      padding: 9px;
      display: flex;

      div {
        text-align: center;
      }

      div:nth-of-type(1) {
        width: 140px;
      }

      div:nth-of-type(2) {
        width: 126px;
      }

      div:nth-of-type(3) {
        width: 109px;
        border: none;
      }
    }

    .header {
      padding: 9px;
      background: #00a63a;
      font-size: 16px;
      color: #ffffff;

      div {
        border-right: 1px solid #00a63a;
      }
    }

    .list {
      font-size: 14px;
      color: #808080;
    }

    .list:nth-of-type(2n + 1) {
      background: #f2f6fc;

      div {
        border-right: 1px solid #ffffff;
      }
    }

    .list:nth-of-type(2n) {
      background: #ffffff;

      div {
        border-right: 1px solid #f2f6fc;
      }
    }
  }
}
// 收益明细 ---end---
</style>