import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    basicInfo: {},
    userInfo: {},
    userGroupData: {},
    checkUpMachineUnfinish: false
  },
  mutations: {
    setBasicInfo(state, payload) {
      state.basicInfo = payload;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setUserGroupData(state, payload) {
      state.userGroupData = payload;
    },
    setCheckUpMachineUnfinish(state) {
      state.checkUpMachineUnfinish = true;
    }
  },
  actions: {
  },
  modules: {
  }
})
