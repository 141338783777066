<template>
	<div class="detailShop">
		<div class="detailShop-1">
			<div class="detailShop-1-1">
				<img class="detailShop-1-1-1" />
				<div class="detailShop-1-1-2">{{ info.business_license }}</div>
				<div class="detailShop-1-1-3">
					<div>{{ info.type_name }}</div>
				</div>
			</div>
			<div class="detailShop-1-2">
				<div v-for="(item, index) in data" :key="index">
					<div class="title">{{ item.title }}</div>
					<div class="content">{{ item.content }}</div>
				</div>
			</div>
		</div>
		<div class="detailShop-2">店铺屏幕（<span>1</span>）</div>
		<div v-if="!info.machine || !info.machine.length" class="detailShop-3">
			<img :src="require('@/assets/other/noData.png')" />
		</div>
		<Card v-else v-for="item in info.machine" :key="item.id" :info="item" />
	</div>
</template>

<script>
	import Card from "./Card.vue";
	import { $get } from "@/utils/request.js";
	export default {
		name: "detailShop",
		components: {
			Card,
		},
		data() {
			return {
				data: [
					{
						title: "店铺规模",
					},
					{
						title: "屏幕数量",
					},
					{
						title: "消费水平",
					},
					{
						title: "负责人姓名",
					},
					{
						title: "负责人电话",
					},
					{
						title: "店铺地址",
					},
				],
				info: {},
			};
		},
		created() {
			const { id } = this.$route.params;
			$get(`/home/shop/detail/${id}`).then((res) => {
				this.info = res;
				[
					"scale",
					"install_num",
					"consume",
					"contacts_name",
					"contacts_mobile",
				].forEach((item, index) => {
					this.data[index].content = res[item];
				});
				this.data[this.data.length - 1].content =
					res.province_name + res.city_name + res.region_name + res.address;
			});
		},
	};
</script>

<style lang="scss" scoped>
	.detailShop {
		min-height: 100%;
		box-sizing: border-box;
		background: #f2f5fc;
		padding-bottom: 15px;

		&-1 {
			padding: 25px 21px 30px 26px;
			background-color: #fff;

			&-1 {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding-bottom: 25px;
				border-bottom: 1px solid #f2f5fc;

				&-1 {
					width: 104px;
					height: 110px;
					background-color: rgba(0, 0, 0, 0.5);
				}

				&-2 {
					font-size: 18px;
					line-height: 100%;
					margin-top: 10px;
				}

				&-3 {
					width: 100%;
					height: 19px;
					margin-top: 10px;
					display: flex;
					justify-content: center;

					div {
						line-height: 19px;
						text-align: center;
						background: #fbf2cb;
						border-radius: 4px;
						font-size: 12px;
						color: #ff9100;
						margin-right: 10px;
						padding: 0 12px;
					}

					div:nth-last-of-type(1) {
						margin: 0;
					}
				}
			}

			&-2 {
				padding-top: 28px;

				> div:after {
					content: "";
					clear: both;
					display: block;
				}

				> div {
					margin-top: 10px;

					> div {
						float: left;
						font-size: 16px;
					}

					.title {
						width: 94px;
						color: #808080;
					}

					.content {
						width: 224px;
						margin-left: 10px;
					}
				}
			}
		}

		&-2 {
			padding: 15px;
			line-height: 100%;
			font-size: 16px;

			span {
				color: #00a63a;
			}
		}

		&-3 {
			text-align: center;
			padding-top: 20px;

			img {
				width: 221px;
			}
		}

		.card {
			margin: 0 auto 10px;
		}

		.card:nth-last-of-type(1) {
			margin-bottom: 0;
		}
	}
</style>