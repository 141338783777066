<template>
  <div class="enterprise">
    <Card
      content="企业认证"
      :src="require('@/assets/authentication/enterpriseLogo.png')"
    />
    <div class="enterprise-1 w">
      <div>企业法人信息</div>
    </div>
    <van-form @submit="onSubmit">
      <template v-for="(item, index) in info">
        <div class="enterprise-1 w" v-if="item.type === 'w'" :key="index">
          <div>{{ item.content }}</div>
        </div>

        <template v-else-if="item.type === 'popup'">
          <van-field
            :key="index"
            class="red"
            readonly
            :value="item.model"
            :name="item.name"
            required
            :label="item.label"
            placeholder="请选择"
            input-align="right"
            error-message-align="right"
            @click="item.showPicker = true"
            right-icon="arrow"
            :rules="item.rules"
          />
          <van-popup
            v-model="item.showPicker"
            position="bottom"
            :key="index + item.name"
          >
            <van-picker
              show-toolbar
              :loading="item.loading"
              :columns="item.columns"
              @confirm="onConfirm($event, index)"
              @cancel="item.showPicker = false"
            />
          </van-popup>
        </template>

        <van-field
          v-else-if="item.type === 'uploader'"
          class="uploader"
          :class="item.class"
          required
          :name="item.name"
          :key="index"
          :rules="item.rules"
        >
          <template #label v-if="item.label">
            {{ item.label }}（
            <span style="color: #ff0000">{{
              item.num.reduce((o, t) => o + info[t].model.length, 0)
            }}</span>
            / {{ item.num.length }} ）
          </template>
          <template #input>
            <van-uploader v-model="item.model" :name="item.name">
              <div
                class="upLoad"
                :style="{
                  'background-image': `url(${item.url})`,
                }"
              >
                <div>{{ item.content }}</div>
              </div>
            </van-uploader>
          </template>
        </van-field>

        <van-field
          v-else
          :key="index"
          v-model="item.model"
          :class="item.class"
          :name="item.name"
          :label="item.label"
          :placeholder="item.placeholder"
          :type="item.type"
          required
          :input-align="item.align || 'right'"
          :error-message-align="item.align || 'right'"
          :rules="item.rules"
        />
      </template>

      <van-button class="btn" round block type="info" native-type="submit"
        >提交</van-button
      >
    </van-form>
  </div>
</template>

<script>
import Card from "./Card.vue";
import { Form, Field, Button, Popup, Picker, Uploader } from "vant";
import { $get, $post } from "@/utils/request.js";
export default {
  name: "authenticationEnterprise",
  components: {
    vanForm: Form,
    vanField: Field,
    vanButton: Button,
    vanPopup: Popup,
    vanPicker: Picker,
    vanUploader: Uploader,
    Card,
  },
  data() {
    const defaultRules = [{ required: true, message: "" }];
    return {
      info: [
        {
          type: "text",
          model: "",
          name: "firm_name",
          label: "公司名称",
          placeholder: "请输入公司名称",
          rules: defaultRules,
        },
        {
          type: "text",
          model: "",
          name: "user_name",
          label: "联系人",
          placeholder: "请输入联系人名称",
          rules: defaultRules,
        },
        {
          type: "tel",
          model: "",
          name: "mphone",
          label: "联系电话",
          placeholder: "请输入电话",
          rules: [
            ...defaultRules,
            { pattern: /\d/, message: "请输入正确电话" },
          ],
        },
        {
          type: "textarea",
          model: "",
          class: "textarea",
          name: "area_name",
          align: "left",
          label: "企业地址",
          placeholder: "请输入企业地址",
          rules: defaultRules,
        },
        {
          type: "text",
          model: "",
          name: "email",
          label: "邮箱",
          placeholder: "请输入邮箱",
          rules: defaultRules,
        },
        {
          type: "w",
          content: "银行卡信息",
        },
        {
          type: "popup",
          loading: true,
          model: "",
          modelId: "",
          name: "bank_open_name",
          label: "开户银行",
          showPicker: false,
          rules: [
            { required: true, message: "请选择证件类型", trigger: "onChange" },
          ],
          columns: [],
        },
        {
          type: "number",
          model: "",
          name: "bank_account",
          label: "公账银行卡号",
          placeholder: "请输入银行卡号",
          rules: defaultRules,
        },
        {
          type: "textarea",
          model: "",
          class: "textarea",
          name: "bank_open_area",
          align: "left",
          label: "开户行地址",
          placeholder: "请输入开户行地址",
          rules: defaultRules,
        },
        {
          type: "w",
          content: "企业信息",
        },
        {
          type: "text",
          model: "",
          name: "permit_no",
          label: "纳税人识别号",
          placeholder: "请输入纳税人识别号",
          rules: defaultRules,
        },
        {
          type: "popup",
          loading: true,
          model: "",
          modelId: "",
          name: "industry_id",
          label: "所属行业",
          showPicker: false,
          rules: [
            { required: true, message: "请选择所属行业", trigger: "onChange" },
          ],
          columns: [],
        },
        {
          type: "uploader",
          model: [],
          name: "permit_pic",
          label: "营业执照",
          num: [12],
          rules: [
            { required: true, message: "请上传营业执照", trigger: "onChange" },
          ],
          content: "请上传营业执照",
          url: require("@/assets/authentication/upLoad.png"),
        },
        {
          type: "uploader",
          model: [],
          name: "identity_front",
          label: "法人身份证照片",
          num: [13, 14],
          content: "请上传身份证正面",
          url: require("@/assets/authentication/identityCard1.png"),
          class: "uploader2",
        },
        {
          type: "uploader",
          model: [],
          name: "identity_rear",
          rules: [
            {
              validator: () => {
                return (
                  this.info[13].model.length + this.info[14].model.length >= 2
                );
              },
              message: "请上传法人身份证照片",
              trigger: "onChange",
            },
          ],
          content: "请上传身份证背面",
          url: require("@/assets/authentication/identityCard2.png"),
          class: "uploader2 uploader3",
        },
      ],
    };
  },
  created() {
    $get("/home/getBankList").then((res) => {
      this.info[6].columns = res.map((item) => ({
        bank_code: item.bank_code,
        text: item.bank_name,
      }));
      this.info[6].loading = false;
    });

    $get("/home/industry/list").then((res) => {
      this.info[11].columns = res.map((item) => ({
        id: item.id,
        text: item.industry_name,
      }));
      this.info[11].loading = false;
    });
  },
  methods: {
    onSubmit(values) {
      values.bank_code = this.info[6].modelId;
      values.industry_id = this.info[11].modelId;

      let count = 0;
      ["permit_pic", "identity_front", "identity_rear"].forEach((item) => {
        const formData = new FormData();
        formData.append("file", values[item][0].file);

        $post("/uploadToHome", formData).then((res) => {
          values[item] = res[0].url;
          count++;

          count >= 3 &&
            $post("/home/submitUserBankAuth", values).then(() => {
              this.$router.replace({ name: "shop" });
            });
        });
      });
    },
    onConfirm(value, index) {
      if (typeof value === "object") {
        this.info[index].modelId = value.id || value.bank_code;
        this.info[index].model = value.text;
      } else {
        this.info[index].model = value;
      }
      this.info[index].showPicker = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.enterprise {
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 10px;
  background: #f2f5fc;

  .w {
    background-color: #fff;
  }

  &-1 {
    padding: 15px;

    div {
      color: #000000;
      font-size: 20px;
      font-weight: bold;
      line-height: 100%;
      padding-left: 10px;
      border-left: 5px solid #33b761;
    }
  }

  .btn {
    width: 343px;
    height: 44px;
    font-size: 16px;
    color: #ffffff;
    margin: 30px auto 28px;
    background: #00a63a;
  }

  .upLoad {
    width: 342px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;

    div {
      width: 100%;
      position: absolute;
      font-size: 16px;
      color: #33b761;
      text-align: center;
      bottom: 35px;
    }
  }

  .upLoad2 {
    height: 200px;

    div {
      color: #808080;
      bottom: 24px;
    }
  }

  .uploader3 {
    padding-top: 0;
    margin-top: -11px;
  }
}
</style>