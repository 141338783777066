var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"paving"},[_c('van-tabs',{attrs:{"title-inactive-color":"#272F37","title-active-color":"#00A63A","line-width":"1.866667rem","line-height":"0.08rem"},on:{"change":_vm.handleChange},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.tabs),function(item){return _c('van-tab',{key:item.name,attrs:{"title":((item.title) + "(" + (item.count) + ")"),"badge":item.badge,"name":item.name,"replace":"","to":item.to}})}),1),(_vm.state % 2 !== 1)?_c('van-loading',{attrs:{"vertical":"","size":"0.8rem","text-size":"0.4rem"}},[_vm._v("加载中...")]):_vm._e(),(!_vm.list.data.length && _vm.state)?_c('div',{staticClass:"paving-1",style:({
			'background-image': ("url(" + (require('@/assets/other/noData.png')) + ")"),
		})},[_c('router-link',{attrs:{"to":{ name: 'shop' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
		var navigate = ref.navigate;
return [_c('div',{staticClass:"btn",on:{"click":navigate}},[_vm._v("还没有环保机，前往购机")])]}}],null,false,2014501829)})],1):(_vm.list.data.length)?[_c('div',{staticClass:"paving-2",on:{"scroll":_vm.handleScroll}},_vm._l((_vm.list.data),function(item){return _c('router-link',{key:item.id,attrs:{"to":{ name: 'deliverySigning', params: { id: item.id } },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
		var navigate = ref.navigate;
return [_c('Card',{attrs:{"info":item},nativeOn:{"click":function($event){return navigate.apply(null, arguments)}}})]}}],null,true)})}),1),_c('router-link',{attrs:{"to":{ name: 'shop' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
		var navigate = ref.navigate;
return [_c('div',{staticClass:"paving-3",on:{"click":navigate}},[_c('img',{attrs:{"src":require('@/assets/other/shop.png')}}),_c('span',[_vm._v("去购机")])])]}}])})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }