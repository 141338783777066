<template>
	<div class="equipment-card">
		<van-cell
			class="equipment-card-1"
			:border="false"
			is-link
			title="设备铺装"
			:clickable="false"
			:to="{ name: 'paving', params: { page: 'all', machine_data: info } }"
		/>
		<div class="equipment-card-2">
			<router-link
				v-for="(item, index) in data"
				:key="index"
				:to="{ name: 'paving', params: { page: item.to, machine_data: info } }"
				tag="div"
				custom
				v-slot="{ navigate }"
			>
				<IconBlock :info="item" @click.native="navigate" />
			</router-link>
		</div>
	</div>
</template>

<script>
	import { Cell } from "vant";
	import IconBlock from "@/components/IconBlock.vue";
	export default {
		components: {
			vanCell: Cell,
			IconBlock,
		},
		props: {
			info: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				data: [
					{
						count: 0,
						max: 99,
						url: require("@/assets/team/bf.png"),
						content: "待铺设",
						to: "already",
					},
					{
						count: 0,
						max: 99,
						url: require("@/assets/team/ing.png"),
						content: "铺装中",
						to: "ing",
					},
					{
						count: 0,
						max: 99,
						url: require("@/assets/team/af.png"),
						content: "待签收",
						to: "sign",
					},
				],
			};
		},
		watch: {
			info(newValue) {
				this.data[0].count = newValue.uninstall;
				this.data[1].count = newValue.installing;
				this.data[2].count = newValue.unfinish;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.equipment-card {
		width: 345px;
		height: 112px;
		background: #ffffff;
		border-radius: 12px;

		&-1 {
			padding: 11px 0;
			font-size: 16px;
			border-radius: 12px;

			span {
				margin-left: 15px;
				color: #000000;
			}

			i {
				margin-right: 5px;
			}
		}

		&-2 {
			margin-top: 3px;
			padding: 0 20px 0 23px;
			height: 47px;
			display: flex;
			justify-content: space-between;
		}
	}
</style>