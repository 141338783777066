<template>
	<div class="identity">
		<Card
			content="身份认证"
			:src="require('@/assets/authentication/identityLogo.png')"
		/>

		<div class="identity-1">
			<van-form @submit="onSubmit">
				<van-field
					v-model="realname"
					name="realname"
					label="姓名"
					placeholder="请输入姓名"
					input-align="right"
					error-message-align="right"
					:rules="[{ required: true, message: '' }]"
				/>

				<van-field
					class="red"
					readonly
					name="picker"
					:value="card_type"
					label="证件类型"
					placeholder="请选择"
					input-align="right"
					error-message-align="right"
					@click="showPicker = true"
					right-icon="arrow"
					:rules="[
						{ required: true, message: '请选择证件类型', trigger: 'onChange' },
					]"
				/>
				<van-popup v-model="showPicker" position="bottom">
					<van-picker
						show-toolbar
						:columns="columns"
						@confirm="onConfirm"
						@cancel="showPicker = false"
					/>
				</van-popup>

				<van-field
					v-model="card_no"
					name="card_no"
					label="证件号码"
					placeholder="用于身份核验，请正确填写"
					input-align="right"
					error-message-align="right"
					:rules="[
						{ required: true, message: '' },
						{ pattern, message: '用于身份核验，请正确填写' },
					]"
				/>
				<van-button class="btn" round block type="info" native-type="submit"
					>提交</van-button
				>
			</van-form>
		</div>
	</div>
</template>

<script>
	import { Form, Field, Button, Popup, Picker } from "vant";
	import { $get, $post } from "@/utils/request.js";
	import Card from "./Card.vue";
	export default {
		name: "authenticationIdentity",
		components: {
			vanForm: Form,
			vanField: Field,
			vanButton: Button,
			vanPopup: Popup,
			vanPicker: Picker,
			Card,
		},
		data() {
			return {
				realname: "",
				card_type: "",
				card_no: "",

				columns: ["居民身份证", "户口本", "军官证", "护照", "港澳通行证"],
				showPicker: false,

				pattern: /\d/,
			};
		},
		methods: {
			onSubmit(values) {
				console.log("submit", values);
				$post('/home/submitUserAuth', {
					realname: this.realname,
					card_type: this.card_type,
					card_no: this.card_no
				}).then(res => {
					
				})
			},
			onConfirm(value) {
				this.card_type = value;
				this.showPicker = false;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.identity {
		width: 100%;
		height: 100%;
		background-color: #f2f5fc;

		&-1 {
			margin-top: 15px;

			.name {
				color: #323233;
				font-size: 16px;
			}

			.btn {
				width: 343px;
				height: 44px;
				margin: 50px auto 0;
				font-size: 16px;
				line-height: 44px;
				background-color: #00a63a;
				opacity: 0.4;
			}
		}
	}
</style>

<style lang="scss">
</style>