<template>
	<div class="paving">
		<van-tabs
			@change="handleChange"
			v-model="active"
			title-inactive-color="#272F37"
			title-active-color="#00A63A"
			line-width="1.866667rem"
			line-height="0.08rem"
		>
			<van-tab
				v-for="item in tabs"
				:key="item.name"
				:title="`${item.title}(${item.count})`"
				:badge="item.badge"
				:name="item.name"
				replace
				:to="item.to"
			/>
		</van-tabs>

		<van-loading
			vertical
			size="0.8rem"
			text-size="0.4rem"
			v-if="state % 2 !== 1"
			>加载中...</van-loading
		>

		<div
			class="paving-1"
			:style="{
				'background-image': `url(${require('@/assets/other/noData.png')})`,
			}"
			v-if="!list.data.length && state"
		>
			<router-link :to="{ name: 'shop' }" custom v-slot="{ navigate }">
				<div class="btn" @click="navigate">还没有环保机，前往购机</div>
			</router-link>
		</div>

		<template v-else-if="list.data.length">
			<div class="paving-2" @scroll="handleScroll">
				<router-link
					v-for="item in list.data"
					:key="item.id"
					:to="{ name: 'deliverySigning', params: { id: item.id } }"
					custom
					v-slot="{ navigate }"
				>
					<Card @click.native="navigate" :info="item" />
				</router-link>
			</div>
			<router-link :to="{ name: 'shop' }" custom v-slot="{ navigate }">
				<div class="paving-3" @click="navigate">
					<img :src="require('@/assets/other/shop.png')" />
					<span>去购机</span>
				</div>
			</router-link>
		</template>
	</div>
</template>

<script>
	import { Tab, Tabs, Loading } from "vant";
	import Card from "./Card.vue";
	import { $get } from "@/utils/request.js";
	export default {
		name: "paving",
		components: {
			vanTab: Tab,
			vanTabs: Tabs,
			vanLoading: Loading,
			Card,
		},
		data() {
			return {
				active: this.$route.params.page,
				list: { current_page: 1, data: [] },
				tabs: [
					{
						title: "全部",
						count: 0,
						name: "all",
						to: {
							params: { page: "all" },
						},
					},
					{
						title: "待铺装",
						count: 0,
						name: "already",
						to: {
							params: { page: "already" },
						},
					},
					{
						title: "铺装中",
						count: 0,
						name: "ing",
						to: {
							params: { page: "ing" },
						},
					},
					{
						title: "待签收",
						count: 0,
						name: "sign",
						to: {
							params: { page: "sign" },
						},
					},
				],
				contrast: {
					already: 0,
					ing: 1,
					sign: 2,
				},
				state: 0,
				scrollBol: false,
			};
		},
		async created() {
			this.upDateList();
			let { machine_data } = this.$route.params;
			if (!machine_data) {
				await $get("/home/getSaleData").then((res) => {
					machine_data = res.machine_data;
				});
			}
			this.tabs[0].count = machine_data.total;
			this.tabs[1].count = machine_data.uninstall;
			this.tabs[2].count = machine_data.installing;
			this.tabs[3].count = machine_data.unfinish;
		},
		methods: {
			handleChange() {
				this.list = { current_page: 1, data: [] };
				this.state = 0;
				this.upDateList();
			},
			upDateList() {
				const opts = { page: this.list.current_page };
				const status = this.contrast[this.active];
				!isNaN(status) && (opts.status = status);
				$get("/home/machine/list", opts).then((res) => {
					this.list = {
						...this.list,
						last_page: res.last_page,
						current_page: res.current_page,
						total: res.total,
					};
					this.list.data.push(...res.data);
					this.state++;
					this.scrollBol = false;
				});
			},
			handleScroll(e) {
				if (this.scrollBol) return;
				const { scrollTop, clientHeight, scrollHeight } = e.srcElement;
				if (scrollHeight - scrollTop - clientHeight < 5) {
					this.scrollBol = true;
					// 滚动条触底;
					if (this.list.lastPage > this.list.current_page) {
						this.list.current_page += 1;
						this.state++;
						this.upDateList();
					}
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.paving {
		height: 100%;
		background: #f2f5fc;

		&-1,
		&-2 {
			height: calc(100% - 40px);
		}

		&-1 {
			position: relative;
			background-size: 222px auto;
			background-repeat: no-repeat;
			background-position: center 35%;

			.btn {
				width: 305px;
				height: 44px;
				position: absolute;
				margin: auto;
				left: 0;
				right: 0;
				background: #00a63a;
				box-shadow: 2px 2px 4px rgba(0, 50, 17, 0.2);
				border-radius: 109px;
				bottom: 15%;
				font-size: 16px;
				line-height: 44px;
				text-align: center;
				color: #ffffff;
			}
		}

		&-2 {
			box-sizing: border-box;
			padding: 10px;
			overflow: scroll;
			-webkit-overflow-scrolling: touch;

			.card {
				margin: 0 auto 10px;
			}

			.card:nth-last-of-type(1) {
				margin: 0 auto;
			}
		}

		&-3 {
			position: fixed;
			right: 0;
			bottom: 25%;
			width: 95px;
			height: 32px;
			background: #00a63a;
			box-shadow: 2px 2px 4px rgba(0, 57, 20, 0.3);
			border-radius: 100px 0px 0px 100px;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 20px;
			}

			span {
				font-size: 16px;
				margin-left: 4px;
				color: #ffffff;
			}
		}

		.van-loading {
			position: absolute;
			top: 45%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
</style>