<template>
	<div
		class="contract"
		:style="
			1 == 2 && {
				'background-image': `url(${require('@/assets/other/noData.png')})`,
			}
		"
	>
	  <div class="list" v-for="(item, index) in list" :key="index">
		<Card :item="item" />
	</div>
	</div>
</template>

<script>
	import Card from "./Card.vue";
	import { $get } from "@/utils/request.js";
	export default {
		name: "contract",
		components: {
			Card,
		},
		data() {
			return {
				page: 1,
				list: [],
				lastPage: 0
			}
		},
		mounted() {
			this.getContractList()
		},
		methods: {
			// 获取合同列表
			getContractList() {
				$get('/home/contractList', {
					page: this.page
				}).then(res => {
					this.list.push(...res.data)
					this.lastPage = res.last_page
				})
			}
		}
	};
</script>

<style lang="scss" scoped>
	.contract {
		min-height: 100%;
		background: #f2f6fc;
		background-repeat: no-repeat;
		background-size: 222px auto;
		background-position: center 40%;
		box-sizing: border-box;

		.list {
			padding-top: 15px;
		}

		.card {
			margin: 0 auto 15px;
		}

		.card:nth-last-of-type(1) {
			margin: auto;
		}
	}
</style>