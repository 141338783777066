<template>
	<div class="delivery-signing">
		<div class="pr">
			<div class="delivery-signing-1">
				<img class="delivery-signing-1-1" :src="info.product_img" />
				<div class="delivery-signing-1-2">
					<div>{{ info.product_name }}</div>
					<div>{{ info.product_remark }}</div>
				</div>
			</div>

			<van-cell
				class="delivery-signing-2"
				:title="`￥${info.product_price || 0}`"
				:title-style="{ color: '#00A63A' }"
				:value="`x${info.num || 1}`"
				:clickable="false"
			/>

			<img
				class="signFor"
				:src="require('@/assets/other/signFor.png')"
				v-if="info.status === 3"
			/>
		</div>

		<van-cell title="订单编号" :value="info.order_no" :clickable="false" />
		<!-- <van-cell title="规格型号" value="21.5英寸" :clickable="false" /> -->
		<van-cell title="数量" :value="info.num" :clickable="false" />
		<van-cell
			class="red"
			title="合计"
			:value="`￥${info.product_total || 0}`"
			:clickable="false"
		/>

		<Signature v-if="info.status === 2" @confirm="handleConfirm" />
	</div>
</template>

<script>
	import { Cell } from "vant";
	import { $get, $post } from "@/utils/request.js";
	import Signature from "@/components/Signature";
	export default {
		name: "deliverySigning",
		components: {
			vanCell: Cell,
			Signature,
		},
		data() {
			return {
				info: {},
			};
		},
		created() {
			const { id } = this.$route.params;
			$get(`/home/machine/detail/${id}`).then((res) => {
				this.info = res;
			});
		},
		methods: {
			handleConfirm() {
				$post("/home/machine/machineFinish", { id: this.$route.params.id }).then(
					() => {
						this.$router.back();
					}
				);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.delivery-signing {
		min-height: 100%;
		background: #f2f5fc;

		.pr {
			position: relative;
		}

		.signFor {
			width: 107px;
			position: absolute;
			top: 58px;
			right: 16px;
		}

		&-1 {
			padding: 10px 17px 15px 16px;
			background-color: #fff;
			display: flex;

			&-1 {
				width: 76px;
				height: 76px;
				box-sizing: border-box;
				border: 1px solid #f5f5f5;
				background: radial-gradient(
					circle,
					rgba(255, 255, 255, 0) 0%,
					rgba(182, 182, 182, 0.1) 100%
				);
				border-radius: 8px;
			}

			&-2 {
				width: 252px;
				margin-left: 14px;
				padding-top: 3px;

				div:nth-of-type(1) {
					font-size: 18px;
					line-height: 100%;
				}

				div:nth-of-type(2) {
					font-size: 14px;
					margin-top: 6px;
					line-height: 16px;
					color: #999999;
				}
			}
		}

		.van-cell {
			padding: 17px 15px;

			> div {
				line-height: 100%;
				font-size: 16px;
			}

			.van-cell__title {
				color: #808080;
			}

			.van-cell__value {
				color: #000000;
			}
		}

		.red.van-cell {
			.van-cell__value {
				color: #00a63a;
			}
		}

		&-2 {
			margin-bottom: 10px;

			.van-cell__value {
				font-size: 14px;
				color: #333333;
			}
		}

		.signature {
			position: fixed;
			bottom: 0;
		}
	}
</style>