<template>
	<canvas
		ref="canvas"
		:width="width"
		:height="height"
		:style="{
			'background-image': `url(${require('@/assets/team/progress1.png')})`,
		}"
	></canvas>
</template>

<script>
	const progress = require("@/assets/team/progress2.png");
	export default {
		props: {
			progress: {
				type: Number,
				default: 0,
			},
		},
		data() {
			return {
				img: null,
				ctx: null,
				width: 306,
				height: 149,
				time: 0,
				allTime: 2000 * this.progress > 800 ? 2000 * this.progress : 800,
			};
		},
		watch: {
			progress(val) {
				this.img = new Image();
				this.img.src = progress;
				this.img.onload = this.init;

				this.allTime = 2000 * val > 800 ? 2000 * val : 800;
			},
		},
		methods: {
			init() {
				const { canvas } = this.$refs;
				this.ctx = canvas.getContext("2d");

				this.ctx.translate(153, 149);
				this.time = Date.now();

				this.draw();
			},
			draw() {
				const time = Date.now() - this.time;
				let progress = (time / this.allTime) * this.progress;
				progress > this.progress && (progress = this.progress);

				this.ctx.globalCompositeOperation = "source-over";
				this.ctx.clearRect(-153, -149, this.width, this.height);

				this.ctx.drawImage(this.img, -153, -149, this.width, this.height);

				this.ctx.moveTo(0, 0);
				this.ctx.arc(0, 0, 153, 0, (1 + progress) * Math.PI);

				this.ctx.globalCompositeOperation = "destination-in";
				this.ctx.fill();

				progress < this.progress && requestAnimationFrame(this.draw);
			},
		},
	};
</script>

<style lang="scss" scoped>
	canvas {
		background-size: 100% 100%;
	}
</style>