<template>
	<div class="not">
		<div></div>
		<div
			class="not-1"
			:style="{
				'background-image': `url(${require('@/assets/share/notBg.png')})`,
			}"
		>
			<img class="not-1-1" :src="require('@/assets/share/doll.png')" />
			<div class="not-1-2">
				购屏成功或成为加盟商即可获得专属 邀请码实现财富名利双丰收
			</div>
			<img class="not-1-3" :src="require('@/assets/other/plosticFreeEarch.png')" />
		</div>

		<div class="not-2">立即购机</div>
	</div>
</template>

<script>
	export default {};
</script>

<style lang="scss" scoped>
	.not {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		&:before,
		&:after {
			content: "";
			display: block;
		}

		&-1 {
			width: 375px;
			height: 349px;
			box-sizing: border-box;
			background-size: 100% 100%;
			font-size: 0;
			padding-top: 27px;
			text-align: center;

			&-1 {
				width: 140px;
			}

			&-2 {
        width: 256px;
				font-size: 16px;
				line-height: 20px;
				color: #000000;
        margin: 4px auto 0;
			}

      &-3 {
        width: 162px;
        margin-top: 50px;
      }
		}

		&-2 {
			width: 345px;
			height: 44px;
			line-height: 44px;
			text-align: center;
			font-size: 16px;
			color: #00a63a;
			background: #ffffff;
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
			border-radius: 109px;
		}
	}
</style>