<template>
  <div
    class="identity"
    :style="{
      'background-image': `url(${require('@/assets/other/identity.png')})`,
    }"
    @click="getCheckUserAudit()"
  >
    <span>身份认证</span>
    <span>为了保证您的交易信息，请先进行实名认证</span>
  </div>
</template>

<script>
import { $get } from "@/utils/request.js";
export default {
  methods: {
    // 检查用户是否已实名认证
    getCheckUserAudit() {
      $get("/home/checkUserAudit").then((res) => {
        if (res.length) {
          // 去实名认证
          localStorage.setItem("wxLoginStatus", true);
          window.location.href = res;
        } else {
          // 去企业认证
          this.$router.push({ name: "authenticationEnterprise" });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.identity {
  width: 375px;
  height: 104px;
  background-size: 100% 100%;
  position: relative;

  span {
    position: absolute;
    line-height: 100%;
  }

  span:nth-of-type(1) {
    font-size: 20px;
    color: #ffffff;
    top: 32px;
    left: 30px;
  }

  span:nth-of-type(2) {
    font-size: 13px;
    color: #ffffff;
    opacity: 0.8;
    top: 59px;
    left: 30px;
  }
}
</style>