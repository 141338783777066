<template>
	<div
		class="card"
	>
    <div class="left" @click.stop="handleCard()">
      <img class="card-1" src="@/assets/contract/logo.png" />
      <div class="card-2">{{ item.contract_name }}</div>
    </div>
		<img class="downloadIcon" src="@/assets/contract/downloadIcon.png" @click.stop="handleDownload" v-if="item.status == 3">
		<div class="card-3" :class="item.status == 1 ? 'col_ff0' : 'col_63a'" v-else>{{ item.status_name }}</div>
	</div>
</template>

<script>
  import { $get } from "@/utils/request";
	export default {
		props: ['item'],
		methods: {
      // 点击卡片
      handleCard() {
        if(this.item.status == 1) {
          // 待签署 - 生成签署链接
          this.getContractDetailsSign()
        }else {
          // 已签署/已生效 - 跳转合同查看链接
          localStorage.setItem("wxLoginStatus", true);
          window.location.href = this.item.contract_url
        }
      },

      // 生成签署链接
      getContractDetailsSign() {
        $get(`/home/contractDetailsSign/${this.item.id}`).then((res) => {
          localStorage.setItem("wxLoginStatus", true);
          window.location.href = res.redirect_url
        });
      },


      // 下载合同
      handleDownload() {
        window.location.href = this.item.contract_download_url
      }
		}
	};
</script>

<style lang="scss" scoped>
	.card {
		width: 345px;
		height: 90px;
		box-sizing: border-box;
		padding: 20px 15px;
		background: #ffffff;
		box-shadow: 2px 2px 4px rgba(4, 56, 69, 0.1);
		border-radius: 12px;
		text-align: center;
		font-size: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      // flex: 0 0 auto;
      display: flex;
      align-items: center;
    }

		&-1 {
			width: 50px;
      height: 50px;
      margin-right: 10px;
		}

		&-2 {
			color: #000000;
			font-size: 18px;
      font-weight: 400;
			line-height: 26px;
		}

		&-3 {
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      &.col_ff0 {
        color: #FF0000;
      }
      &.col_63a {
        color: #00A63A;
      }
		}
    .downloadIcon {
      width: 30px;
      height: 30px;
    }
	}
</style>