<template>
	<router-link
		:to="{ name: 'detailShop', params: { id: info.id } }"
		custom
		v-slot="{ navigate }"
	>
		<div class="card" @click="navigate">
			<div
				class="card-1"
				:style="{
					'background-image': `url(${info.img})`,
				}"
			>
				<div :class="`color${info.install_status}`">
					<div>{{ info.install_status ? "已铺装" : "待铺装" }}</div>
				</div>
			</div>

			<div class="card-2">
				<div class="card-2-1">
					<div class="title">{{ info.business_license }}</div>
					<div class="tag">{{ info.type_name }}</div>
				</div>

				<div class="card-2-2">
					屏幕数量: <span>{{ info.install_num }}</span>
				</div>

				<div class="card-2-3">
					地区：{{
						info.province_name +
						info.city_name +
						info.region_name +
						info.address
					}}
				</div>
			</div>

			<!-- <div class="card-3"></div> -->
		</div>
	</router-link>
</template>

<script>
	export default {
		props: {
			info: {
				type: Object,
				default: () => {},
			},
		},
	};
</script>

<style lang="scss" scoped>
	.card {
		width: 345px;
		height: 140px;
		box-sizing: border-box;
		box-shadow: 1px 1px 2px rgba(1, 41, 56, 0.1);
		border-radius: 12px;
		background: #ffffff;
		padding: 15px;
		padding-right: 12px;
		display: flex;
		justify-content: space-between;
		position: relative;

		&-1 {
			width: 104px;
			height: 110px;
			border-radius: 6px;
			background-size: cover;
			background-position: center 10%;

			> div {
				width: 55px;
				height: 15px;
				line-height: 15px;
				border-radius: 6px 0;
				text-align: center;
				font-size: 12px;
				color: #ffffff;

				> div {
					transform: scale(0.8333333333333334);
				}
			}

			.color0 {
				background-color: rgba(0, 0, 255, 0.5);
			}

			.color1 {
				background-color: rgba(255, 0, 0, 0.5);
			}
		}

		&-2 {
			width: 204px;
			height: 100%;
			box-sizing: border-box;
			margin-left: 10px;
			padding-top: 4px;

			&-1 {
				height: 40px;
				display: flex;
				justify-content: space-between;

				.title {
					width: 130px;
					font-size: 18px;
					color: #000000;
					line-height: 120%;
					margin-top: -2px;

					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}

				.tag {
					// min-width: 48px;
					height: 19px;
					line-height: 19px;
					text-align: center;
					background: #fbf2cb;
					border-radius: 4px;
					font-size: 12px;
					color: #ff9100;
					padding: 0 10px;
				}
			}

			&-2,
			&-3 {
				font-size: 12px;
				color: #808080;
			}

			&-2 {
				margin-top: 18px;

				span {
					color: #00a63a;
				}
			}

			&-3 {
				margin-top: 8px;
				line-height: 14px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}

		&-3 {
			width: 14px;
			height: 14px;
			background: #ff0000;
			border-radius: 50%;
			position: absolute;
			margin: auto;
			top: 0;
			bottom: 0;
			right: 12px;
		}
	}
</style>