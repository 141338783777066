<template>
  <div class="binding-card">
    <div class="binding-card-1">
      <img :src="require('@/assets/wallet/warning.png')" />
      <span>请使用与实名认证同身份证下的银行</span>
    </div>

    <van-form @submit="onSubmit">
      <van-field
        v-for="(item, index) in data"
        :key="index"
        v-model="item.model"
        :name="item.name"
        :readonly="!item.name"
        required
        :label="item.label"
        :placeholder="item.placeholder"
        input-align="right"
        error-message-align="right"
        @blur="fieldBlur($event, index)"
        :rules="[
          item.name ? { required: true, message: '' } : { required: false },
        ]"
      />

      <van-button class="btn" round block type="info" native-type="submit"
        >提交</van-button
      >
    </van-form>
  </div>
</template>

<script>
import { Form, Field, Button } from "vant";
import { $get, $post } from "@/utils/request.js";
export default {
  components: {
    vanForm: Form,
    vanField: Field,
    vanButton: Button,
  },
  data() {
    return {
      id: null,
      data: [
        {
          model: "",
          name: "account_name",
          label: "姓名",
          placeholder: "请输入姓名",
        },
        {
          model: "",
          name: "id_card_num",
          label: "身份证号",
          placeholder: "请输入您的身份证号码",
        },
        {
          model: "",
          name: "bank_account",
          label: "银行卡号",
          placeholder: "请输入卡号",
        },
        {
          model: "",
          name: "",
          label: "所属银行",
          placeholder: "所属银行",
        },
        {
          model: "",
          name: "bank_bind_mobile",
          label: "预留手机号",
          placeholder: "请输入开卡预留的手机号",
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getDetail();
    }
  },
  methods: {
    // 输入框失焦
    fieldBlur(e, index) {
      if (index == 2) {
        this.getSearchBank();
      }
    },

    // 查询银行卡详情
    getDetail() {
      $post(`/home/userBank/detail/${this.id}`).then((res) => {
        this.data[0].model = res.account_name;
        this.data[1].model = res.id_card_num;
        this.data[2].model = res.bank_account;
        this.data[3].model = res.bank_name;
        this.data[4].model = res.bank_bind_mobile;
      });
    },

    // 查询卡类型
    getSearchBank() {
      if (this.data[2].model) {
        $get(`/home/userBank/searchBank/${this.data[2].model}`).then((res) => {
          if (res) {
            this.data[3].model = res.bank_name;
          } else {
            this.$toast("请输入正确的银行卡号");
          }
        });
      } else {
        this.$toast("请输入银行卡号");
      }
    },

    // 提交
    onSubmit(params) {
      if (this.id) {
        params.id = this.id;
      }
      delete params[""];
      $post("/home/userBank/editBank", params).then((res) => {
        this.$toast(this.id ? "修改成功" : "添加成功");
        this.$router.back();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.binding-card {
  min-height: 100%;
  background: #f2f5fc;

  &-1 {
    height: 46px;
    padding-left: 13px;
    display: flex;
    align-items: center;

    img {
      width: 20px;
    }

    span {
      font-size: 16px;
      color: #000000;
      margin-left: 8px;
    }
  }

  .btn {
    width: 343px;
    height: 44px;
    font-size: 16px;
    color: #ffffff;
    margin: 120px auto 20px;
    background: #00a63a;
  }
}
</style>