<template>
	<div class="item-card">
		<div
			class="item-card-1"
			:style="
				info.id <= 3 && {
					'background-image': `url(${require(`@/assets/eliteRanking/rank${info.id}.png`)})`,
				}
			"
		>
			{{ info.id > 3 ? info.id : "" }}
		</div>

		<img class="item-card-2" :src="info.avatar" />

		<div class="item-card-3">
			<div class="item-card-3-1">{{ info.true_name }}</div>
			<div class="item-card-3-2">
				<div v-for="(item, index) in info.roles" :key="index">{{ item }}</div>
			</div>
		</div>

		<div class="item-card-4">
			<span>{{ info.earnings }}</span>
			<img :src="require('@/assets/eliteRanking/quota.png')" />
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			info: {
				type: Object,
				default: () => {},
			},
		},
	};
</script>

<style lang="scss" scoped>
	.item-card {
		width: 355px;
		height: 73px;
		box-sizing: border-box;
		padding: 15px 15px 16px 5px;

		> div {
			height: 100%;
			float: left;
		}

		img {
			float: left;
		}

		&-1 {
			width: 25px;
			line-height: 42px;
			text-align: center;
			font-size: 20px;
			color: #000000;
			background-size: 100% auto;
			background-position: center;
			background-repeat: no-repeat;
		}

		&-2 {
			width: 42px;
			height: 42px;
			border-radius: 50%;
			background-color: rgba(0, 0, 0, 0.5);
			margin-left: 10px;
		}

		&-3 {
			width: 183px;
			box-sizing: border-box;
			margin-left: 10px;
			padding-top: 1px;
			text-align: left;

			&-1 {
				font-size: 16px;
				color: #000000;
				line-height: 100%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			&-2 {
				margin-top: 6px;
				height: 26px;
				font-size: 0;
				white-space: nowrap;
				overflow: scroll;

				div {
					display: inline-block;
					line-height: 20px;
					padding: 0 12px;
					border-radius: 100px;
					background: rgba(0, 166, 58, 0.2);
					font-size: 12px;
					color: #00a63a;
					margin-right: 10px;
				}

				div:nth-last-of-type(1) {
					margin: 0;
				}
			}
		}

		&-4 {
			width: 58px;
			height: 100%;
			margin-left: 6px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			span {
				margin-top: 1.5px;
				line-height: 100%;
				font-size: 14px;
				color: #ffa811;
			}

			img {
				margin-top: 3px;
				width: 21px;
			}
		}
	}
</style>