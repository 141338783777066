<template>
	<div
		class="contract"
	>
    <van-row class="hintTitle"><van-col class="line" span="5"></van-col><van-col span="19" style="line-height:52px"><h4 class="temlistHeader">缺袋设备列表</h4></van-col></van-row>
    <van-row>
        <van-col span="6" class="TableHeader"><h5>设备ID号</h5></van-col>
        <van-col span="6" class="TableHeader"><h5>店招名</h5></van-col>
        <van-col span="6" class="TableHeaderAdress"><h5>安装位置</h5></van-col>
    </van-row>
    <div class="viewline"></div>
    <van-list :finished="finished" finished-text >
        <van-row class="list" v-for="(item, index) in list" :key="index">
            <van-col span="5" class="TableContent"><h6>{{item.mac_id}}</h6></van-col>
            <van-col span="5" class="TableContent"><h6>{{item.shop_name}}</h6></van-col>
            <van-col span="5" class="TableContent"><h6>{{item.address}}</h6></van-col>
        </van-row>
    </van-list>
    <!-- <van-loading
     vertical
     size="0.8rem"
     text-size="0.4rem"
     v-if="state % 2 !== 1"
    ></van-loading> -->
	</div>
</template>

<script>
    import { Loading, List, Col, Row, Sidebar, SidebarItem } from "vant";
    import Vue from 'vue';
	import { $get, $post  } from "@/utils/request.js";
    Vue.use(List).use(Col).use(Row);
	export default {
		name: "ofstock",
        components: {
            vanLoading: Loading
        },
		data() {
			return {
                scroll: false,
                current_page:1,
                finished: false,
				page: 1,
				list: [],
				lastPage: 0,
                total: 0,				// 数据总条数
                last_page: 1
			}
		},
        created() {
            this.getlist();
            const ele = document.documentElement;
            window.onscroll = () => {
                console.log('=====')
                var oTop1=ele.scrollTop==0?document.body.scrollTop:ele.scrollTop;
                if (this.scroll) return;
                if (ele.scrollHeight - oTop1 - ele.clientHeight <= 5) {
                    this.scroll = true;
                    console.log('====='+this.last_page)
                    if (this.last_page > this.current_page) {
                        this.current_page += 1;
                        this.getlist()
                    }
                }
            }
        },
        beforeDestroy() {
            window.onscroll = null;
        },
		mounted() {
			this.getContractList()
		},
		methods: {
			// 获取缺袋提示列表
			getContractList() {
			},
            getlist() {
                $get("/home/machine/outOfStockList").then((res) => {
                    if(res){
                        console.log('获取数据===' + JSON.stringify(res))
                        this.last_page = res.last_page
                        this.current_page = res.current_page
                        this.list = res.data

                    }
                })
            },
            onReachBottom() {
          
            }
		}
	};
</script>

<style lang="scss" scoped>
	.contract {
		min-height: 100%;
		background-repeat: no-repeat;
		background-size: 222px auto;
		background-position: center 40%;
		box-sizing: border-box;
		.list {
			padding-top: 15px;
		}

		.card {
			margin: 0 auto 15px;
		}

		.card:nth-last-of-type(1) {
			margin: auto;
		}
	}
    .TableHeader {
        text-align: center;
        font-size: 16px;
        color: #9CA1AA;

    }
    .TableHeaderAdress {
        text-align: left;
        font-size: 16px;
        margin-left: 20px;
        color: #9CA1AA;
    }
    .viewline {
        height: 2px;
        color: #9CA1AA;
        width: 100%;
    }
    .TableContent {
        text-align: center;;
        font-size: 20px;
        color: #242426;
        font-weight: 40px;
        display: inline-block;
    }
    .hintTitle {
        display: block;
        .line {
           width: 5px; 
           height: 22px;
           background-color: #4CA77E;
           margin: 15px;
           overflow: hidden;
        }
        .temlistHeader1 {
            color:#242422;
            height: 22px;
            margin: 15px;
            text-align: center;
            font-size: 12px;
            font-weight: bold;
        }
    }
</style>