<template>
	<div class="myOrder">
		<van-tabs
			v-model="tabsIndex" @change="tabsChange"
			title-inactive-color="#272F37"
			title-active-color="#00A63A"
			line-width="1.12rem"
			line-height="0.08rem"
		>
			<van-tab
				v-for="(item, index) in tabs"
				:key="index"
				:title="item.title"
				:name="index"
				replace
				:to="item.to"
			/>
		</van-tabs>

		<div
			class="myOrder-content"
			:style="
				1 == 2 && {
					'background-image': `url(${require('@/assets/other/noData.png')})`,
				}
			" @scroll="handleScroll"
		>
			<router-link
				:to="{ name: 'orderDetail', query: { id: item.id } }"
				v-for="(item, index) in orderList"
				:key="index"
				custom
				v-slot="{ navigate }"
			>
				<Card :item="item" @click.native="navigate" />
			</router-link>
		</div>
	</div>
</template>

<script>
	import Card from "./Card";
	import { Tab, Tabs } from "vant";
	import { $get } from "@/utils/request.js";
	export default {
		name: "myOrder",
		components: {
			vanTab: Tab,
			vanTabs: Tabs,
			Card,
		},
		data() {
			return {
				tabsIndex: 0,
				tabs: [
					{
						title: "全部",
						name: "all",
						to: {
							params: { page: "all" },
						},
						status: null
					},
					{
						title: "待付款",
						name: "payment",
						to: {
							params: { page: "payment" },
						},
						status: 0
					},
					{
						title: "待审核",
						name: "examine",
						to: {
							params: { page: "examine" },
						},
						status: 1
					},
					{
						title: "已完成",
						name: "complete",
						to: {
							params: { page: "complete" },
						},
						status: 2
					},
					{
						title: "已取消",
						name: "cancel",
						to: {
							params: { page: "cancel" },
						},
						status: 3
					},
				],

				orderList: [], // 订单列表
				page: 1, // 当前页
				lastPage: 0 // 最后页
			};
		},
		created() {
			this.tabs.forEach((item, index) => {
				if(this.$route.params.page == item.name) {
					this.tabsIndex = index
				}
			})
			console.log('?????????',this.tabsIndex)
			this.getOrderList()
		},
		methods: {
			// 获取订单列表
			getOrderList() {
				$get('/home/order/list', {
					page: this.page,
					status: this.tabs[this.tabsIndex].status
				}).then(res => {
					this.lastPage = res.last_page
					this.orderList.push(...res.data)
				})
			},

			// 导航栏切换
			tabsChange() {
				// 重置数据 start
				this.page = 1
				this.lastPage = 0
				this.orderList = []
				// 重置数据 end
				this.getOrderList()
			},

			// 触发滚动条
			handleScroll(e) {
				let { scrollTop, clientHeight, scrollHeight } = e.srcElement
				if(scrollTop + clientHeight >= scrollHeight) {
					// 滚动条触底
					if(this.lastPage > this.page) {
						this.page += 1
						this.getOrderList()
					}
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.myOrder {
		background: #f2f5fc;
		height: 100%;

		.card {
			margin-top: 10px;
		}

		&-content {
			height: calc(100% - 40px);
			overflow: scroll;
			-webkit-overflow-scrolling: touch;
			padding-bottom: 10px;
			box-sizing: border-box;

			background-size: 222px auto;
			background-repeat: no-repeat;
			background-position: center 40%;
		}
	}
</style>