<template>
	<div class="info">
		<div class="info-1">
			<div
				class="progress"
				:style="{
					width: `${proportion * 100}%`,
				}"
			></div>
		</div>
		<div class="info-2">
			<div class="info-left">
				<slot name="left"></slot>
			</div>
			<div class="info-right">
				<slot name="right"></slot>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			proportion: {
				type: Number,
				default: 0,
			},
		},
	};
</script>

<style lang="scss" scoped>
	.info {
		width: 335px;
		height: 28px;

		&-1 {
			height: 4px;
			background: #7e6f4d;
			border-radius: 100px;
			overflow: hidden;

			.progress {
				height: 100%;
				background: #f8ebcd;
				border-radius: 100px;
			}
		}

		&-2 {
			display: flex;
			justify-content: space-between;

			> div {
				margin-top: 6px;
				height: 18px;
				font-size: 14px;
				color: #ffedbd;

				> span {
					font-size: 18px;
					color: #ffffff;
					margin: 0 5px;
				}
			}
		}
	}
</style>