<template>
	<div class="my-card">
		<img class="my-card-1" :src="info.avatar" />

		<div class="my-card-2">
			<div class="my-card-2-1">我的排名：{{ info.id || "暂无" }}</div>
			<div class="my-card-2-2">
				<div v-for="(item, index) in info.roles" :key="index">{{ item }}</div>
			</div>
		</div>

		<div class="my-card-3">
			<span>{{ info.earnings || "暂无业绩" }}</span>
			<img :src="require('@/assets/eliteRanking/quota.png')" />
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			info: {
				type: Object,
				default: () => {
					return {};
				},
			},
		},
	};
</script>

<style lang="scss" scoped>
	.my-card {
		width: 355px;
		height: 84px;
		box-sizing: border-box;
		padding: 12px 20px;

		&-1,
		&-2,
		&-3 {
			height: 100%;
			float: left;
		}

		&-1 {
			width: 59px;
			height: 59px;
			border-radius: 50%;
		}

		&-2 {
			width: 183px;
			box-sizing: border-box;
			margin-left: 7px;
			padding-top: 6px;
			text-align: left;

			&-1 {
				font-size: 18px;
				color: #000000;
				line-height: 100%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			&-2 {
				margin-top: 6px;
				height: 30px;
				font-size: 0;
				white-space: nowrap;
				overflow: scroll;

				div {
					display: inline-block;
					line-height: 24px;
					padding: 0 14px;
					border-radius: 100px;
					background: rgba(103, 206, 139, 0.2);
					font-size: 12px;
					color: #00a63a;
					margin-right: 10px;
				}

				div:nth-last-of-type(1) {
					margin: 0;
				}
			}
		}

		&-3 {
			width: 60px;
			height: 100%;
			margin-left: 6px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			span {
				margin-top: 1.5px;
				line-height: 100%;
				font-size: 14px;
				color: #ffa811;
			}

			img {
				margin-top: 3px;
				width: 21px;
			}
		}
	}
</style>