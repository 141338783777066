<template>
	<div class="organization-team">
		<div class="organization-team-1" ref="card">
			<img :src="userGroupData.group_avatar" />
			<div @click="handleClick">
				<span>{{ userGroupData.group_name }}</span>
				<img
					:src="require('@/assets/team/write.png')"
					v-if="this.userGroupData.is_group_user"
				/>
			</div>
		</div>

		<van-collapse
			:border="false"
			@change="handleChange"
			v-model="active"
			accordion
		>
			<van-collapse-item :border="false" :is-link="false" ref="recommend">
				<template #title>
					<van-icon
						name="play"
						class="icon"
						:class="active === 0 && 'select'"
					/>
					<span class="title">上级团队长</span>
				</template>
				<div class="content">
					<img
						:src="
							userGroupData.group_user_data &&
							userGroupData.group_user_data.avatar
						"
					/>
					<div>
						<div>
							{{
								userGroupData.group_user_data &&
								userGroupData.group_user_data.realname
							}}
						</div>
						<div>
							粉丝 :
							{{
								userGroupData.group_user_data &&
								userGroupData.group_user_data.fans_count
							}}
						</div>
					</div>
				</div>
			</van-collapse-item>
			<van-collapse-item
				v-if="userGroupList.data.length"
				:border="false"
				:is-link="false"
				ref="fans"
			>
				<template #title>
					<van-icon
						name="play"
						class="icon"
						:class="active === 1 && 'select'"
					/>
					<span class="title">队员（{{ userGroupList.total }}）</span>
				</template>
				<div
					:style="{
						'max-height': wrapperHeight + 'px',
					}"
					class="overflow"
					@scroll="handleScroll"
				>
					<div
						class="content"
						v-for="item in userGroupList.data"
						:key="item.id"
					>
						<img :src="item.avatar" />
						<div>
							<div>{{ item.realname }}</div>
							<div>粉丝 : {{ item.fans_count }}</div>
						</div>
					</div>
				</div>
			</van-collapse-item>
		</van-collapse>
	</div>
</template>

<script>
	import { Collapse, CollapseItem, Icon } from "vant";
	import { $get } from "@/utils/request.js";
	import { mapState, mapMutations } from "vuex";
	export default {
		name: "organizationTeam",
		components: {
			vanCollapse: Collapse,
			vanCollapseItem: CollapseItem,
			vanIcon: Icon,
		},
		data() {
			return {
				active: 0,
				userGroupList: { data: [], current_page: 1 },
				wrapperHeight: 0,
				scrollBol: false,
			};
		},
		computed: {
			...mapState(["userGroupData"]),
		},
		created() {
			$get("/home/getUserGroupData").then((res) => {
				this.setUserGroupData(res || {});

				+res.is_group_user === 1 && this.upDateList();
			});
		},
		methods: {
			...mapMutations(["setUserGroupData"]),
			handleChange() {
				if (!this.userGroupList.data.length) return;
				const { card, recommend, fans } = this.$refs;
				this.wrapperHeight =
					document.documentElement.clientHeight -
					(10 +
						card.clientHeight +
						recommend.$el.querySelector(".van-collapse-item__title")
							.clientHeight +
						fans.$el.querySelector(".van-collapse-item__title").clientHeight);
			},
			handleScroll(e) {
				if (this.scrollBol) return;
				const { scrollTop, clientHeight, scrollHeight } = e.srcElement;
				if (scrollHeight - scrollTop - clientHeight < 5) {
					this.scrollBol = true;
					// 滚动条触底;
					if (this.fansInfo.lastPage > this.fansInfo.current_page) {
						this.fansInfo.current_page += 1;
						this.upDateList();
					}
				}
			},
			upDateList() {
				$get("/home/getUserGroupList", {
					page: this.userGroupList.current_page,
				}).then((res) => {
					this.userGroupList = {
						...this.userGroupList,
						last_page: res.last_page,
						current_page: res.current_page,
						total: res.total,
					};

					this.userGroupList.data.push(...res.data);
					this.scrollBol = false;
				});
			},
			handleClick() {
				if (!this.userGroupData.is_group_user) return;
				this.$router.push({ name: "informationTeam" });
			},
		},
	};
</script>

<style lang="scss" scoped>
	.organization-team {
		height: 100%;
		background: #f2f5fc;

		&-1 {
			width: 375px;
			height: 150px;
			padding-top: 27px;
			margin-bottom: 10px;
			box-sizing: border-box;
			text-align: center;
			background: linear-gradient(49deg, #a78851 0%, #dcc799 100%);

			> img {
				width: 70px;
				height: 70px;
				border-radius: 50%;
			}

			> div {
				margin-top: 8px;
				height: 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 0;

				span {
					font-size: 16px;
					color: #ffffff;
					line-height: 100%;
				}

				img {
					height: 100%;
					margin-left: 7px;
				}
			}
		}

		.icon {
			left: -3px;
			bottom: 1.5px;
			transition: 0.5s;
			color: #808080;
			font-size: 18px;
		}

		.icon.select {
			transform: rotateZ(90deg);
			color: #a78851;
		}

		.title {
			margin-left: 3px;
			color: #000000;
			font-size: 16px;
		}

		.content {
			width: 375px;
			height: 70px;
			padding: 10px 35px;
			box-sizing: border-box;

			> img {
				float: left;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				background-color: rgba(0, 0, 0, 0.5);
			}

			> div {
				width: 245px;
				float: left;
				margin-left: 10px;
				padding-top: 6px;

				div {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					line-height: 100%;
				}

				div:nth-of-type(1) {
					font-size: 16px;
					color: #000000;
				}

				div:nth-of-type(2) {
					margin-top: 8px;
					font-size: 14px;
					color: #808080;
				}
			}
		}

		.overflow {
			overflow: scroll;
			-webkit-overflow-scrolling: touch;
		}
	}
</style>