<template>
	<div class="card2">
		<Progress :progress="+info.next_rate || 0" class="card2-7" />
		<div class="card2-1">{{ info.next_level_name }}</div>
		<div class="card2-2 center">
			业绩突破 {{ (next_sales / 2) | fixed }}{{ unit }}
		</div>
		<div class="card2-3">
			{{ ((next_sales / 3) * 2) | fixed }}{{ unit }}<br />业绩达成
		</div>
		<div class="card2-4 center">目前业绩</div>
		<div class="card2-5 center">
			{{ team_money | fixed }}<span>{{ team_money_unit }}</span>
		</div>
		<!-- <div class="card2-6 center">超过50%的无塑家园人</div> -->
		<div class="card2-8">00.00万<br />起步</div>
		<div class="card2-9">{{ next_sales | fixed }}{{ unit }}<br />业绩达成</div>
	</div>
</template>

<script>
	import Progress from "./Progress.vue";
	export default {
		components: {
			Progress,
		},
		filters: {
			fixed: function (value) {
				if (typeof value === "undefined") return "";
				return value.toFixed(2);
			},
		},
		props: {
			info: {
				type: Object,
				default: () => {},
			},
		},
		computed: {
			next_sales() {
				return parseFloat(this.info.next_sales);
			},
			unit() {
				return this.info.next_sales ? this.info.next_sales.replace(/[0-9]*/g, "").replace(/\./, "") : '';
			},
			team_money() {
				return parseFloat(this.info.team_money);
			},
			team_money_unit() {
				return this.info.team_money ? this.info.team_money.replace(/[0-9]*/g, "").replace(/\./, "") : '';
			},
		},
	};
</script>

<style lang="scss" scoped>
	.card2 {
		position: relative;
		width: 345px;
		height: 264px;
		background: #53575c;
		border: 1px solid #707070;
		border-radius: 15px;

		> div {
			position: absolute;
			font-size: 12px;
			color: #ffffff;
			line-height: 100%;
		}

		.center {
			width: 100%;
			text-align: center;
		}

		&-1 {
			top: 73px;
			left: 35px;
			transform: translateX(-25%);
		}

		&-2 {
			top: 30px;
		}

		.card2 &-3 {
			top: 63px;
			line-height: 16px;
			right: 35px;
			transform: translateX(25%);
			text-align: center;
		}

		&-4 {
			top: 93px;
		}

		.card2 > &-5 {
			top: 116px;
			font-size: 40px;
			line-height: inherit;

			span {
				margin-left: 4px;
				font-size: 20px;
			}
		}

		&-6 {
			top: 180px;
		}

		&-7 {
			position: absolute;
			top: 48px;
			left: 21px;
		}

		.card2 > &-8 {
			min-width: 82px;
			top: 204px;
			line-height: 16px;
			text-align: center;
		}

		.card2 > &-9 {
			min-width: 82px;
			right: 0;
			top: 204px;
			line-height: 16px;
			text-align: center;
		}
	}
</style>