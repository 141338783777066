<template>
	<div class="pieces">
		<div class="pieces-1">{{ info.count }}</div>
		<div class="pieces-2">{{ info.describe }}</div>
	</div>
</template>

<script>
	export default {
		props: {
			info: {
				type: Object,
				default: () => {
					return {}
				},
			},
		},
	};
</script>

<style lang="scss" scoped>
	.pieces {
		width: 93px;
		height: 43px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		> div {
			line-height: 100%;
		}

		&-1 {
			font-size: 18px;
			color: #00a63a;
		}

		&-2 {
			font-size: 14px;
			color: #808080;
		}
	}
</style>