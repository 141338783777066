<template>
	<div class="order-card">
		<van-cell
			class="order-card-1"
			:border="false"
			is-link
			title="我的订单"
			:clickable="false"
			value="全部"
			:to="{ name: 'myOrder', params: { page: 'all' } }"
		/>
		<div class="order-card-2">
			<router-link
				v-for="(item, index) in info"
				:key="index"
				:to="{ name: 'myOrder', params: { page: item.page } }"
				custom
				v-slot="{ navigate }"
			>
				<IconBlock :info="item" color="#333333" @click.native="navigate" />
			</router-link>
		</div>
	</div>
</template>

<script>
	import { Cell } from "vant";
	import IconBlock from "@/components/IconBlock.vue";
	export default {
		components: {
			vanCell: Cell,
			IconBlock,
		},
		methods: {
			abc() {
				console.log(111);
			}
		},
		data() {
			return {
				info: [
					{
						url: require("@/assets/my/payment.png"),
						content: "待付款",
						page: "payment",
					},
					{
						url: require("@/assets/my/examine.png"),
						content: "待审核",
						page: "examine",
					},
					{
						url: require("@/assets/my/complete.png"),
						content: "已完成",
						page: "complete",
					},
					{
						url: require("@/assets/my/cancel.png"),
						content: "已取消",
						page: "cancel",
					},
				],
			};
		},
	};
</script>

<style lang="scss" scoped>
	.order-card {
		width: 345px;
		height: 112px;
		background: #ffffff;
		border-radius: 12px;

		&-1 {
			padding: 11px 0;
			font-size: 16px;
			border-radius: 12px;

			.van-cell__title span {
				margin-left: 10px;
				color: #333333;
				font-weight: bold;
			}

			.van-cell__value span {
				color: #00a63a;
			}

			i {
				margin-right: 5px;
				color: #00a63a;
			}
		}

		&-2 {
			margin-top: 3px;
			padding: 0 20px;
			height: 47px;
			display: flex;
			justify-content: space-between;
		}
	}
</style>