<template>
	<div class="agreement">
		<div class="agreement-1"></div>

		<Signature />
	</div>
</template>

<script>
	import Signature from "@/components/Signature";
	export default {
		name: "agreement",
		components: {
			Signature,
		},
	};
</script>

<style lang="scss" scoped>
	.agreement {
		min-height: 100%;
		background: #f2f6fc;

		.signature {
			position: fixed;
			bottom: 0;
		}
	}
</style>