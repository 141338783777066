<template>
  <div class="my-bank-card">
    <div class="my-bank-card-1" v-if="!list.length">当前还未绑定银行卡</div>

    <template v-else>
      <div
        class="list"
        v-for="(item, index) in list"
        :key="index"
        @click="toLink(item.id)"
      >
        <div class="my-bank-card-2">
          <img class="cardIcon" src="@/assets/wallet/cardIcon.png" alt="" />
          <div class="icon">
            <img :src="item.bank_img" v-if="item.bank_img" />
            <span v-else>{{ item.bank_name }}</span>
          </div>
          <div class="number">
            <div v-for="i in 3" :key="i" class="dian">
              <i v-for="j in 4" :key="j"></i>
            </div>
            <div>{{ item.short_bank_account }}</div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="addBtnBol">
      <router-link :to="{ name: 'bindingCard' }" custom v-slot="{ navigate }">
        <div class="my-bank-card-3" @click="navigate">
          <img :src="require('@/assets/wallet/add.png')" />
          <span>添加银行卡</span>
        </div>
      </router-link>
    </template>
  </div>
</template>

<script>
import { $get } from "@/utils/request.js";
export default {
  data() {
    return {
      list: [],
      addBtnBol: false,
    };
  },
  created() {
    this.addBtnBol = this.$route.query.addBtnBol || true;
    this.getList();
  },
  methods: {
    // 查询银行卡列表
    getList() {
      $get("/home/userBank/list").then((res) => {
        this.list = res;
      });
    },

    // 跳转
    toLink(id) {
      this.$router.push({ name: "bindingCard", query: { id, id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.my-bank-card {
  min-height: 100%;
  box-sizing: border-box;
  background: #f2f5fc;
  padding: 15px;

  &-1 {
    width: 345px;
    line-height: 80px;
    text-align: center;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 1px 1px 2px rgba(3, 59, 105, 0.1);
    font-size: 16px;
    color: #808080;
  }

  &-2 {
    width: 345px;
    height: 120px;
    position: relative;
    margin-bottom: 10px;
    box-shadow: 2px 2px 4px rgba(4, 56, 69, 0.1);
    border-radius: 12px;
    background: #fff;
    .cardIcon {
      width: 86px;
      height: 75px;
      position: absolute;
      top: 0;
      right: 0;
    }

    .icon {
      width: 125px;
      height: 25px;
      box-sizing: border-box;
      position: absolute;
      left: 10px;
      top: 10px;
      image {
        width: 100%;
        height: 100%;
      }
      span {
        color: #000;
        font-size: 16px;
      }
    }

    .number {
      width: 220px;
      position: absolute;
      top: 82px;
      left: 0;
      right: 0;
      margin: auto;
      display: flex;
      justify-content: space-between;

      div {
        font-size: 24px;
        line-height: 24px;
        color: #000000;
        margin: auto;
      }
      .dian {
        display: flex;
        align-items: center;

        i {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #000000;
          margin-right: 4px;
        }

        i:nth-last-of-type(1) {
          margin: 0;
        }
      }
    }
  }

  &-3 {
    width: 345px;
    height: 50px;
    box-shadow: 1px 1px 2px rgba(4, 56, 69, 0.1);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    margin-top: 15px;

    img {
      width: 24px;
    }

    span {
      font-size: 16px;
      color: #000000;
      margin-left: 8px;
    }
  }
}
</style>