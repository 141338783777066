<template>
	<div
		@click="handleClick"
		:style="{
			'background-image': `url(${require('@/assets/other/top.png')})`,
		}"
	></div>
</template>

<script>
	export default {
		props: {
			target: {
				type: Element,
				default: () => document.documentElement,
			},
			to: {
				type: Number,
				default: 0,
			},
		},
		methods: {
			handleClick() {
				this.target.scroll({
					top: this.to,
					left: 0,
					behavior: "smooth",
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	div {
		width: 36px;
		height: 36px;
		background-size: 100% 100%;
	}
</style>